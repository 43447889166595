import React from 'react';
import SSO_Redirection from 'app/redirection-SSO';
import { UilExchange } from '@iconscout/react-unicons';
import './redirectComp.scss';
import PopMenu, { PopMenuItem } from './popmenu';
import Tracker from 'app/tracking/tracking-service';
import { Enums } from 'services/dropdown-enums';

const RedirectComp = ({ showSideBar }) => {
  const redirectHandler = () => {
    // call Tracker event for switchTo
    Tracker.EventsProvider(
      Enums.TrackerType.GoogleAnalytics
    ).Redirection.SwitchToLegacyPortal();

    // redirection here..
    SSO_Redirection();
  };

  return (
    <>
      <PopMenu
        id="introJS-item-switch"
        showSideBar={showSideBar}
        title={'Switch'}
        toolTipText={'Switch'}
        menuHeading={'Switch'}
        showTooltip={false}
        path={undefined}
        icon={<UilExchange size="24" className="sidebar-link" />}
      >
        {props => {
          return (
            <>
              <PopMenuItem
                text={`Existing Portal`}
                handler={() => {
                  props.menuSet(false);
                  redirectHandler();
                }}
                loading={false}
              />
            </>
          );
        }}
      </PopMenu>
    </>
  );
};

export default RedirectComp;
