export const en_leadDetails = {
  heading1: 'Lead details',
  heading2: 'Lead notes',
  heading3: 'DocuSign',
  heading4: 'Appointments Scheduling',
  leadTooltip: 'The portal displays up to 6 months of data',
  name: {
    label: 'Name',
    info: 'The name of the customer.'
  },
  phoneNo: {
    label: 'Phone no',
    info: 'The phone number of the customer.'
  },
  email: {
    label: 'Email',
    info: 'The email address of the customer.'
  },
  createdOn: {
    label: 'Created on',
    info: 'When the lead was created in our system.'
  },
  leadType: {
    label: 'Lead type',
    info: 'The type of lead classifed by the user handling the chat.'
  },
  initiatedBy: {
    label: 'Initiated by',
    info: 'Where the chat was started from.'
  },
  // username: {
  //   label: 'Sent By',
  //   info: 'Where the chat was started from.'
  // },
  notificationState: {
    label: 'Status',
    info: 'Status of the lead.'
  },
  sentBy: {
    label: 'Sent By',
    info: 'Lead sent by the user.'
  },
  appointmentStartTime: {
    label: 'Start Time',
    info: 'Start time of the appointment booked.'
  },
  appointmentEndTime: {
    label: 'End Time',
    info: 'End time of the appointment booked.'
  },
  appointmentStatus: {
    label: 'Appointment Status',
    info: 'Status of appointment with the visitor.'
  },
  documentVerified: {
    label: 'Document Verified',
    info: 'Set DocuSign document verified status for lead once you reviewed the document.'
  },
  docuSignNotificationState: {
    label: 'DocuSign Document Status',
    info: 'Status of the document.'
  },
  called: {
    label: 'Called',
    info: 'Did the user try to connect the visitor and business.'
  },
  attemptedCalls: {
    label: 'Attempted calls',
    info: 'Number of call connect attempts.'
  },
  completedCalls: {
    label: 'Completed calls',
    info: 'Number of completed call connect attempts.'
  },
  keywords: {
    label: 'Keywords',
    info: 'Provide the Keywords if the system was able to capture it.'
  },
  flagAsProcessed: {
    label: 'Flag as processed',
    info: 'Flag as processed'
  },
  flagAsUnProcessed: {
    label: 'Flag as Un-Processed',
    info: 'Flag as Un-Processed'
  },
  forwardLead: {
    label: 'Forward',
    info: 'Forward'
  },
  replyToVisitor: {
    label: 'Reply to visitor',
    info: 'Reply to visitor'
  },
  viewChat: {
    label: 'View Conversation',
    info: 'View Conversation'
  },
  viewCompany: {
    label: 'View Company',
    info: 'View Company'
  },
  region: { label: 'Region', info: 'Region' },
  leadCategory: { label: 'Lead Category', info: 'Lead Category' },
  appliedRule: { label: 'Applied Rule', info: 'Applied Rule' },
  forwardLeadModal: {
    title: 'Forward',
    email_label: 'Email Address',
    email_placeholder: 'Enter Email',
    email_info: 'Press enter to add email address.',
    note_label: 'Additional Notes',
    note_placeholder: 'Write a note',
    saveBtn: 'Save',
    sendBtn: 'Send',
    closeBtn: 'Close'
  }
};
export default en_leadDetails;
export { default as fr_leadDetails } from '../fr-FR-LeadDictionary/fr-leadDetails';
export { default as sp_leadDetails } from '../sp-SP-LeadDictionary/sp-leadDetails';
