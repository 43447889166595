import OnRollLogo from 'assets/app-images/OnRollLogo.png';
import ApexchatLogo from 'assets/app-images/ApexchatLogo.png';
import config from 'config';
import { getAppMetaAsync } from 'store/slices/auth/authSlice';
import storage from 'services/utils/storage';

const themePalette = theme => ({
  '--primary': `var(--th-${theme})`,
  '--primary-1': `var(--th-${theme}-1)`,
  '--primary-2': `var(--th-${theme}-2)`,
  '--primary-3': `var(--th-${theme}-3)`,
  '--primary-4': `var(--th-${theme}-4)`,
  '--primary-5': `var(--th-${theme}-5)`,
  '--primary-light': `var(--th-light-${theme})`,
  '--primary-dark': `var(--th-dark-${theme})`,
  '--info-1': `var(--th-light-${theme})`
});

export const changeThemeColor = theme => {
  if (theme) {
    let palette = themePalette(theme);
    for (let key in palette) {
      document.documentElement.style.setProperty(key, palette[key]);
    }
  }
};

export const AppMetaProvider = () => {
  const host = window.location.hostname;
  const _getHostUrl = host => {
    const _subDomainLength = host.split('.')[0].length;
    return host.substring(_subDomainLength + 1);
  };
  const _hostDomainSubString = _getHostUrl(host);
  // let apexchatOwnerCompanyKeys = config.apexchatOwnerCompanyKeys.split(',');
  let _hostDomain =
    host === 'localhost' ? 'apexchat.com' : _hostDomainSubString;
  let isApexHostName = config.apexchatURLS.split(',').includes(_hostDomain);
  let _hostKey = JSON.parse(storage.getItem('AppMetaInfo'))?.['host'];

  const _staticMeta = (function () {
    let staticMetaData = {
      isWhiteLabeled: true,
      androidAppLink:
        'https://play.google.com/store/apps/details?id=com.onroll.live',
      IOSAppLink: 'https://apps.apple.com/pk/app/onroll/id1554277859',
      whatsNewIframeLink: config.onRollIframeUrl
    };
    if (isApexHostName) {
      staticMetaData.isWhiteLabeled = false;
      staticMetaData.androidAppLink =
        'https://play.google.com/store/apps/details?id=com.apexchat.live';
      staticMetaData.IOSAppLink =
        'https://apps.apple.com/pk/app/apexconnect/id1385172978';
      staticMetaData.whatsNewIframeLink = config.apexchatIframeUrl;
    }
    return staticMetaData;
  })();

  let _defaultValues = {
    hostName: 'Portal',
    signupUrl: null,
    allowSignup: false,
    hostLogo: OnRollLogo,
    haveMultipleOccurrences: true,
    isDefaultHost: true,
    isWhiteLabeled: true,
    ssoEnabled: false
  };

  let _defaultApexChatValues = {
    hostName: 'Apex Chat',
    signupUrl: null,
    allowSignup: false,
    hostLogo: ApexchatLogo,
    haveMultipleOccurrences: true,
    isDefaultHost: true,
    isWhiteLabeled: false,
    ssoEnabled: false
  };

  const _getCurrentTheme = () => {
    if (isApexHostName) {
      return 'gray';
    }
  };

  const _dynamicMeta = async function () {
    changeThemeColor(_getCurrentTheme());

    const response = await getAppMetaAsync({
      data: {
        hostDomain: _hostDomain,
        hostKey: _hostKey ? _hostKey : ''
      }
    });
    let payload = {
      ..._defaultValues
    };
    if (response?.data?.data) {
      const { data } = response.data;
      if (isApexHostName && data.haveMultipleOccurrences) {
        payload = { ..._defaultApexChatValues };
      } else if (!data.isDefaultHost) {
        if (!data.hostLogo) {
          data.hostLogo = isApexHostName ? ApexchatLogo : OnRollLogo;
        }
        payload = { ...data, isWhiteLabeled: false };
      }
    }
    return payload;
  };

  return {
    GetStaticMeta: _staticMeta,
    GetDynamicMeta: _dynamicMeta,
    GetCurrentTheme: _getCurrentTheme,
    GetHostUrl: _getHostUrl
  };
};
