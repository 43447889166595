import React from 'react';
import PropTypes from 'prop-types';
import SplashLoader from './splash-loader';
import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Loading_enums } from 'services/dropdown-enums';

const useStyles = makeStyles(() => ({
  centerAlign: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 72px)',
    overflow: 'hidden'
    // alignItems: 'center',
    // textAlign: 'center',
  }
}));

const PageLoading = props => {
  const { loadingType, ...otherProps } = props;
  const classes = useStyles();
  const [fade, setFade] = useState(false);

  useEffect(() => {
    setFade(true);
    return () => {
      setFade(false);
    };
  }, []);

  return (
    <div
      className={`${classes?.centerAlign} ${fade ? 'show-comp' : 'hide-comp'}`}
    >
      <SplashLoader type={Loading_enums.Circular} />;
    </div>
  );
};

PageLoading.propTypes = {
  loadingType: PropTypes.number
};

PageLoading.defaultProps = {
  loadingType: 1
};

export default PageLoading;
