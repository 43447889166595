import {
  animateTabTitleOnMessageReceived,
  getTotalOptInLeadsCount,
  getTotalUnreadMessagesCount,
  getUnSubscribedIncomingChatList
} from 'store/slices/messagingChats/messagingChats';
import store from 'store';
import React, { useEffect } from 'react';
import { OverlayTrigger, Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import renderToolTip from '../renderToolTip';
import './style.scss';
import AnimateScrollArrow from 'pub-components/ui-controls/animate-scroll-arrow';
import { Init_Unread_Messages_Count } from 'app/communication/listeners/listener_unread_messages';
import { TitleTab } from 'pub-components/TitleTab';
import CustomBadge from 'pub-components/CustomBadge';
const RenderSidebarTooltip = ({
  showSidebar,
  title,
  icon,
  showTooltip = true
}) => {
  const unSubscribedChat = useSelector(getUnSubscribedIncomingChatList);
  const allUnReadMsgCounts = useSelector(getTotalUnreadMessagesCount);
  const optinUnReadMsgCounts = useSelector(getTotalOptInLeadsCount);
  let incomingChatsCount = unSubscribedChat.length;
  const location = useLocation();
  useEffect(() => {
    if (title === 'Messaging') {
      Init_Unread_Messages_Count();
      // TitleTab(allCounts, `(New Message)`);
    }
  }, [window.RTC?.status]);

  // UIUX-1896 stop tab title animation when there is no unread message and incomming chat count
  useEffect(() => {
    let allCounts = incomingChatsCount + allUnReadMsgCounts;
    if (allCounts > 0) {
      store.dispatch(animateTabTitleOnMessageReceived());
    } else {
      if (window.tabTitleTimer) clearInterval(window.tabTitleTimer);
    }
  }, [incomingChatsCount, allUnReadMsgCounts]);

  return (
    <>
      {!showSidebar && showTooltip ? (
        <OverlayTrigger placement={'bottom'} overlay={renderToolTip(title)}>
          {icon}
        </OverlayTrigger>
      ) : (
        <>{icon}</>
      )}
      {title === 'Messaging' && (
        <>
          {(allUnReadMsgCounts || optinUnReadMsgCounts) > 0 && (
            <CustomBadge
              style={{ right: '5px', lineHeight: '1' }}
              count={allUnReadMsgCounts + optinUnReadMsgCounts}
              toolTipLabel={`Unread messages count`}
            />
          )}

          {incomingChatsCount > 0 && (
            <OverlayTrigger
              placement={'auto'}
              overlay={renderToolTip(
                `Incoming chat count: ${incomingChatsCount}`
              )}
            >
              <Badge
                bg="secondary"
                className={`incoming-chat-vertical-indicator ${
                  incomingChatsCount > 9 && 'incoming-count-greater-than-9'
                }`}
              >
                <AnimateScrollArrow />
                {incomingChatsCount}
              </Badge>
            </OverlayTrigger>
          )}
        </>
      )}
    </>
  );
};
export default RenderSidebarTooltip;
