import * as React from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar, DateRangePicker, DefinedRange } from 'react-date-range';
import './style.scss';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  format,
  setYear,
  setMonth
} from 'date-fns';
import { defaultStaticRanges } from 'react-date-range'; // this will give you already defined list
import { createStaticRanges } from 'react-date-range'; //pass your object to this method
import InputWithStartIcon from 'pub-components/InputWithStartIcon';
import UilCalender from '@iconscout/react-unicons/icons/uil-calender';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { Button } from 'react-bootstrap';
import ScrollBar from 'pub-components/Scrollbars';
import { Fade, Paper, Popper } from '@material-ui/core';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  beforeYear: setYear(new Date(), 2007),
  beforeSixMonths: setMonth(new Date(), new Date().getMonth() - 6)
};

let customOptions = [
  {
    // this is your predefined date range object
    label: 'Month to date',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.startOfToday
    })
  },
  {
    // this is your predefined date range object
    label: 'Dates before',
    range: () => ({
      startDate: defineds.beforeSixMonths,
      endDate: defineds.startOfToday
    })
  },
  {
    // this is your predefined date range object
    label: 'Dates after',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.startOfToday
    })
  }
];

let customDateRange = createStaticRanges(customOptions);

const InitalSelectionRange = {
  startDate: null,
  endDate: new Date(''),
  key: 'selection'
};

const DateRangeDropdown = ({ onDateChange, id = '' }) => {
  const [show, setShow] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [dateRange, setDateRange] = React.useState(InitalSelectionRange);
  const [focusedRange, setFocusedRange] = React.useState([0, 1]);

  const dateRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    /**
     * close menu if clicked on outside of element
     */
    function handleClickOutside(event) {
      let elem = document.getElementsByClassName('date-range-wrapper');
      if (show && elem.length && !elem[0].contains(event.target)) {
        setShow(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  const openCalendar = () => {
    setShow(!show);
  };

  const openCalendarOnFocus = e => {
    openPopper(e.target);
  };

  const handleRangeSelect = ranges => {
    setDateRange(ranges.selection);
    // setFocusedRange([0, 1]);

    if (dateRef.current !== null) {
      // dateRef.current.updateShownDate = () => {};
      setTimeout(() => {
        console.log('dateRef--', dateRef.current);
        // dateRef.current.dateRange.calendar.updateShownDate = () => {};
        // dateRef.current.dateRange.calendar.focusToDate(
        //   ranges?.selection?.endDate
        // );
      }, 1000);
    }

    setTimeout(() => {
      changePopperPlacement();
    }, 100);
  };

  const applyChanges = () => {
    if (dateRange?.startDate) {
      onDateChange(dateRange);
      openCalendar(false);
      setInputValue(true);
    }
  };

  const getDateValue = () => {
    if (dateRange?.startDate && inputValue) {
      let startDate = format(dateRange?.startDate, 'dd/MM/yyyy');
      let endDate = format(dateRange?.endDate, 'dd/MM/yyyy');
      let value = `${startDate} ${
        endDate && startDate !== endDate ? 'to ' + endDate : ''
      }`;
      return `${value}`;
    }
    return 'Select Date';
  };

  const clearInput = () => {
    setDateRange(InitalSelectionRange);
    setInputValue(false);
    let emptyDateRange = { startDate: '', endDate: '' };
    // reset grid filter state and refetch grid data
    onDateChange(emptyDateRange);
  };

  // set fr (focusedRange) in state
  const onRangeChange = fr => {
    setFocusedRange(fr);
  };

  const openPopper = e => {
    setAnchorEl(e);
    setShow(true);
    setTimeout(() => {
      changePopperPlacement();
    }, 5);
  };

  const changePopperPlacement = () => {
    // let popperElem = document.getElementById('popper-element');
    // let dateRangeElem = document.getElementsByClassName(
    //   'date-dropdown-wrapper'
    // )[0];
    let dateRangeElem = document.getElementById('date-range-scroll-div');
    console.log('isinview', isInViewport(dateRangeElem));
    if (dateRangeElem && !isInViewport(dateRangeElem)) {
      // add left -390px to view popper completely
      console.log('dateRangeElem', dateRangeElem.style);
      dateRangeElem.style.left = '-390px';
    }
  };

  const onIconClick = e => {
    e.stopPropagation();
    let inputElem = document.getElementById(`date-range-${id}`);
    openPopper(inputElem);
  };

  return (
    <div className="date-range-container">
      <InputWithStartIcon
        startTextOrIcon={null}
        endTextOrIcon={
          <>
            {inputValue && <UilTimes onClick={clearInput} />}
            <span onClick={onIconClick} className="d-inline-block">
              <UilCalender />
            </span>
          </>
        }
        name="date-range"
        label="date-range"
        placeholder="Select date"
        handleOnChange={() => console.log('input change')}
        value={getDateValue()}
        readOnly={true}
        onFocus={openCalendarOnFocus}
        id={`date-range-${id}`}
      />
      {/* <Popper
        id="popper-element"
        open={show}
        anchorEl={anchorEl}
        transition
        placement={'bottom-start'}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <div className="date-range-wrapper">
                <DateRangePicker
                  ref={dateRef}
                  className={'date-dropdown-wrapper'}
                  id={'date-range-filter'}
                  ranges={[dateRange]}
                  onChange={handleRangeSelect}
                  staticRanges={[...defaultStaticRanges, ...customDateRange]}
                  maxDate={defineds.startOfToday}
                  onRangeFocusChange={onRangeChange}
                  focusedRange={focusedRange}
                  initialFocusedRange={focusedRange}
                  preventSnapRefocus={true}
                  showSelectionPreview={true}
                  direction="horizontal"
                />
                <div className="action-buttons">
                  <Button
                    className="btn-md detail-button ml-3"
                    type="button"
                    variant="primary"
                    onClick={applyChanges}
                  >
                    Apply
                  </Button>
                  <Button
                    className="btn-md detail-button ml-3"
                    type="button"
                    variant="outline-primary"
                    onClick={openCalendar}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper> */}
      {show && (
        // <ScrollBar
        //   id="date-range-scroll-div"
        //   horizontalScroll={false}
        //   style={{
        //     minHeight: '80px',
        //     minWidth: '580px',
        //     height: 'calc(100vh - 300px)'
        //   }}
        // >
        <div className="date-range-wrapper" id="date-range-scroll-div">
          {/* Range selection */}
          <DateRangePicker
            ref={dateRef}
            className={'date-dropdown-wrapper'}
            id={'date-range-filter'}
            ranges={[dateRange]}
            onChange={handleRangeSelect}
            staticRanges={[...defaultStaticRanges, ...customDateRange]}
            inputRanges={[]}
            maxDate={defineds.startOfToday}
            minDate={defineds.beforeSixMonths}
            startDatePlaceholder={'Start Date'}
            endDatePlaceholder={'End Date'}
            onRangeFocusChange={onRangeChange}
            focusedRange={focusedRange}
            initialFocusedRange={focusedRange}
            preventSnapRefocus={true}
            showSelectionPreview={true}
            direction="horizontal"
            rangeColors={['var(--primary)']}
          />
          <div className="action-buttons">
            <Button
              className="btn-md detail-button ml-3"
              type="button"
              variant="outline-primary"
              onClick={openCalendar}
            >
              Cancel
            </Button>
            <Button
              className="btn-md detail-button ml-3"
              type="button"
              variant="primary"
              onClick={applyChanges}
            >
              Apply
            </Button>
          </div>
        </div>
        // {/* </ScrollBar> */}
      )}
    </div>
  );
};

export default DateRangeDropdown;

const isInViewport = el => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    // rect.bottom <=
    //   (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
