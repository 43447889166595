import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { Profile } from 'api/endpoints';

export const profileSlice = createSlice({
  name: 'themeProfile',
  initialState: {
    profile: {}
  },
  // customAgentImages: [],
  //   defaultAgentImages: [],
  //   apperanceProfileDesktop: {},
  //   apperanceProfileMobile: {}

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setCustomAgentImages: (state, action) => {
      const { companyId, data } = action.payload;
      state.profile[`${companyId}`] = {
        ...state.profile[`${companyId}`],
        customAgentImages: data
      };
    },

    setDefaultAgentImages: (state, action) => {
      const { companyId, data } = action.payload;
      state.profile[`${companyId}`] = {
        ...state.profile[`${companyId}`],
        defaultAgentImages: data
      };
    },

    updateApperanceProfileData: (state, action) => {
      const { companyId, data, key } = action.payload;
      let companyData = state.profile[`${companyId}`];
      if (data) {
        state.profile[`${companyId}`] = {
          ...companyData,
          [`apperanceProfile${key}`]: {
            ...companyData?.[`apperanceProfile${key}`],
            ...data
          }
        };
      } else {
        state.profile[`${companyId}`] = {
          ...companyData,
          [`apperanceProfile${key}`]: data
        };
      }
    },

    setApperanceProfileDesktop: (state, action) => {
      const { companyId, data } = action.payload;
      state.profile[`${companyId}`] = {
        ...state.profile[`${companyId}`],
        apperanceProfileDesktop: data
      };
    },

    setApperanceProfileMobile: (state, action) => {
      const { companyId, data } = action.payload;
      state.profile[`${companyId}`] = {
        ...state.profile[`${companyId}`],
        apperanceProfileMobile: data
      };
    },
    setApperanceProfileDesktopLocal: (state, action) => {
      const { companyId, data } = action.payload;
      state.profile[`${companyId}`] = {
        ...state.profile[`${companyId}`],
        desktopDataLocal: data
      };
    },
    setApperanceProfileMobileLocal: (state, action) => {
      const { companyId, data } = action.payload;
      state.profile[`${companyId}`] = {
        ...state.profile[`${companyId}`],
        mobileDataLocal: data
      };
    },
    removeApperanceProfile: (state, action) => {
      delete state.profile[`${action.payload}`];
    },
    removeAllApperanceProfile: (state, action) => {
      state.profile = {};
    },
    setAppearanceTab: (state, action) => {
      const { companyId, data } = action.payload;
      state.profile[`${companyId}`] = {
        ...state.profile[`${companyId}`],
        selectedTab: data
      };
    }
  }
});

export const {
  setCustomAgentImages,
  setDefaultAgentImages,
  setApperanceProfileDesktop,
  setApperanceProfileMobile,
  removeApperanceProfile,
  removeAllApperanceProfile,
  setApperanceProfileDesktopLocal,
  setApperanceProfileMobileLocal,
  setAppearanceTab,
  updateApperanceProfileData
} = profileSlice.actions;

export const getCustomAgentImages = (state, companyId) => {
  const {
    themeProfile: { profile }
  } = state;
  if (companyId in profile)
    return profile[`${companyId}`].customAgentImages || [];
  else return [];
};

export const getDefaultAgentImages = (state, companyId) => {
  const {
    themeProfile: { profile }
  } = state;
  if (companyId in profile)
    return profile[`${companyId}`].defaultAgentImages || [];
  else return [];
};

export const getApperanceProfileDesktop = (state, companyId, local = false) => {
  const {
    themeProfile: { profile }
  } = state;

  if (companyId in profile) {
    let companyProfile = profile[companyId];
    let data = local
      ? companyProfile?.desktopDataLocal
      : companyProfile?.apperanceProfileDesktop;
    return data || null;
  } else {
    return null;
  }
};
export const getApperanceProfileMobile = (state, companyId, local = false) => {
  const {
    themeProfile: { profile }
  } = state;
  if (companyId in profile) {
    let companyProfile = profile[companyId];
    let data = local
      ? companyProfile?.mobileDataLocal
      : companyProfile?.apperanceProfileMobile;
    return data || null;
  } else return null;
};

export const getLastSelectedImage =
  state =>
  ({ companyId, imageId }) => {
    const {
      themeProfile: { profile }
    } = state;
    if (companyId in profile) {
      let defaultImages = profile[`${companyId}`]['defaultAgentImages'] || [];
      let customImages = profile[`${companyId}`]['customAgentImages'] || [];
      let mergedImages = [...defaultImages, ...customImages];
      let lastImg = mergedImages.filter(
        img => Number(img?.id) === Number(imageId)
      )[0];
      return lastImg || null;
    } else return null;
  };

export const getAppearanceTab = (state, companyId) => {
  const {
    themeProfile: { profile }
  } = state;
  if (companyId in profile) return profile[`${companyId}`].selectedTab || 1;
  else return 1;
};

// ----------------API section-------------------
export const getCompanyAgentImagesById = companyId => {
  let ProfileAPI = `${Profile.get}/${companyId}/agentImages`;
  return ApiServices.get(ProfileAPI);
};
export const getCompanyProfileById = companyId => {
  let ProfileAPI = `${Profile.get}/${companyId}`;
  return ApiServices.get(ProfileAPI);
};
export const updateCompanyProfile = (companyId, data) => {
  let ProfileAPI = `${Profile.get}?companyId=${companyId}`;
  return ApiServices.put(ProfileAPI, data);
};

export const uploadCustomImage = ({ companyId, imageName }, payload) => {
  let ProfileAPI = `${Profile.get}/custom/upload?companyId=${companyId}&imageName=${imageName}`;
  return ApiServices.postAttachements(ProfileAPI, payload);
};

export const deleteCustomImage = (companyId, imageId) => {
  let ProfileAPI = `${Profile.get}/custom/delete?companyId=${companyId}&imageId=${imageId}`;
  return ApiServices.post(ProfileAPI);
};

//  update profile cache in server
export const updateCacheAfterSave = async payload => {
  let updateCacheEndpoint = Profile.updateCache(payload);
  return ApiServices.WCF_post(updateCacheEndpoint)
    .then(async response => {
      if (response.data.success) {
        return Promise.resolve(response.data.success);
      }
    })
    .catch(async err => {
      return Promise.reject('Error:' + err);
    });
};

export default profileSlice.reducer;
