import { selectRoles } from 'store/slices/roles/rolesSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

const HandlePageRoutes = (routes, layout) => {
  const { roles } = useSelector(selectRoles);

  return routes.map((route, key) => {
    if (route.path)
      if (roles?.showLimitedAccessWarning) {
        if (!roles[route.name].displayOnly)
          return (
            <Route
              path={route.path}
              component={route.component}
              exact={true}
              key={`${key}-child`}
            />
          );
        else return null;
      } else
        return (
          <Route
            path={route.path}
            component={route.component}
            exact={true}
            key={`${key}-child`}
          />
        );
    else return null;
  });
};

export default HandlePageRoutes;
