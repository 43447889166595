import store from 'store';
import i18n from 'app/i18n';
import { logout } from 'store/slices/auth/authSlice';
import { setRolesToState } from 'store/slices/roles/rolesSlice';
import connectionService from 'services/utils/connectionIdentifier';
import { setScript } from 'store/slices/script-load/scriptLoadSlice';
import { resetChatsOnLogout } from 'store/slices/messagingChats/messagingChats';
import { UnSubscribeAllChannels } from 'app/communication/services/chat/pub_sub_chat';

export const globalLogout = (shouldRedirect = false) => {
  i18n.changeLanguage('en-US');
  store.dispatch(
    setScript({
      isLoaded: false,
      src: ''
    })
  );
  store.dispatch(logout());
  store.dispatch(setRolesToState({}));

  // if (!storage.getItem('return-url')) {
  //   storage.setItem('return-url', window.location.pathname);
  // }

  // if (shouldRedirect) {
  //   setTimeout(() => {
  //     window.location.href = window.location.origin + '/login';
  //   }, 1000);
  // }

  //Reset MD States & Channels
  clearInterval(window.inter);

  //Reset Tab title timer
  if (window?.tabTitleTimer) {
    clearInterval(window.tabTitleTimer);
  }

  store.dispatch(resetChatsOnLogout());
  if (window.RTC.status && window.RTC.subscribedChannels.length > 0) {
    UnSubscribeAllChannels();
    connectionService.ClearAll();
    setTimeout(() => {
      window.RTC.client.disconnect();
      window.RTC.init = false;
      window.RTC.status = false;
      window.RTC.hasLoaded = false;
    }, 1000);
  }
};
