import React from 'react';
import moment from 'moment';
import CopyButton from 'pub-components/CellRenderer/CopyButton';

const StatusButton = ({ value }) => {
  const date = value ? moment(new Date(value)).format('DD-MMMM-YYYY') : 'N/A';
  return <CopyButton value={date} />;
};

export default StatusButton;
