import React from 'react';
import DateRangeDropdown from 'pub-components/DateRangeDropdown';

const CustomDateFilter = React.forwardRef((props, ref) => {
  const handleChange = value => {
    let filterObj = props?.filterValue;
    filterObj.value = value;
    props.onChange(filterObj);
  };
  let id = props?.filterValue?.name;
  return (
    <div ref={ref}>
      <DateRangeDropdown onDateChange={handleChange} id={id} />
    </div>
  );
});

export default CustomDateFilter;
