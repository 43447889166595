import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';

export const generateFilterTypes = (data = []) => {
  let obj = {};
  data.forEach(col => {
    if (col.enableFilter && col.filterType === 'select') {
      obj[col.name] = {
        ...getFilterOptionsByType(col, true)
      };
    }
  });
  return obj;
};

export const getFilterOptionsByType = (data = {}, config) => {
  let obj = { name: data.name, value: '', type: data.filterType || 'string' };
  switch (obj.type) {
    case 'string':
      obj = {
        ...obj,
        operator: 'contains',
        operators: [
          {
            name: 'contains'
          },
          {
            name: 'startsWith'
          },
          {
            name: 'endsWith'
          }
        ]
      };
      break;
    case 'number':
    case 'select':
      obj = { ...obj };
      break;
    case 'date':
      obj = {
        ...obj,
        operator: 'eq',
        operators: [
          {
            name: 'eq'
          },
          {
            name: 'before'
          },
          {
            name: 'after'
          }
        ]
      };
      // obj['type'] = obj.name;
      break;

    default:
      obj = {
        ...obj,
        operator: 'contains',
        operators: [
          {
            name: 'contains'
          },
          {
            name: 'startsWith'
          },
          {
            name: 'endsWith'
          }
        ]
      };
  }
  // console.log(obj);
  if (config) {
    delete obj['operator'];
    // delete obj['value'];
    // delete obj['type'];
    return obj;
  } else {
    delete obj['operators'];

    return obj;
  }
};

export const generateFilterColumns = (data = []) => {
  return data
    .filter(val => val.enableFilter)
    .map(col => getFilterOptionsByType(col, false));
};

export const getFilterValue = (data, dateTypeKeysProp = []) => {
  let updatedValue =
    typeof data?.value === 'object' && 'startDate' in data?.value
      ? data?.value?.startDate
      : data?.value;

  let dateTypeKeys = [
    'createdOn',
    'startDate',
    'modifiedOn',
    ...dateTypeKeysProp
  ];
  if (
    dateTypeKeys.includes(data.name) &&
    (data.operator === 'eq' || data.operator === 'after')
  ) {
    if (
      data?.value &&
      typeof data?.value === 'object' &&
      'startDate' in data.value
    ) {
      updatedValue = moment(data?.value?.startDate, 'DD-MMM-YYYY');
    } else {
      updatedValue = moment(data.value, 'DD-MMM-YYYY');
    }
  } else if (data.name === 'createdOn' && data.operator === 'before') {
    updatedValue = '2000-01-01T08:00:00.000Z';
  } else if (data.type === 'number') {
    updatedValue = updatedValue ? Number(updatedValue) : '';
  } else if (data.operator === 'contains') {
    updatedValue = `%${updatedValue}%`;
  } else if (data.operator === 'startsWith') {
    updatedValue = `${updatedValue}%`;
  } else if (data.operator === 'endsWith') {
    updatedValue = `%${updatedValue}`;
  }
  return updatedValue;
};

let filterTypeObj = ReactDataGrid.defaultProps.filterTypes;
export const filterTypes = Object.assign({}, filterTypeObj, {
  string: {
    ...filterTypeObj.string,
    operators: [
      { name: 'contains' },
      { name: 'startsWith' },
      { name: 'endsWith' }
    ]
  },
  date: {
    ...filterTypeObj.date,
    operators: [{ name: 'eq' }, { name: 'before' }, { name: 'after' }]
  }
});

export const getFilterValue2 = data => {
  let value =
    typeof data?.value === 'object' && 'endDate' in data?.value
      ? data?.value?.endDate
      : data?.value;
  let updatedValue = '';
  if (
    data.name === 'createdOn' &&
    (data.operator === 'before' || data.operator === 'after')
  ) {
    if (data.operator === 'before') {
      updatedValue = moment(value).local();
    } else if (data.operator === 'after') {
      updatedValue = moment().local();
    } else {
      updatedValue = formatValue(value);
    }
  } else {
    updatedValue = formatValue(value);
  }
  return updatedValue;
};

const formatValue = value => {
  let val = '';
  if (value instanceof Date) {
    val = moment(value, 'DD-MMM-YYYY');
  } else {
    val = value;
  }
  return val;
};

export const getFiltersValue = () => {
  let propertyValue1 = '';
  let propertyValue2 = '';
  let propertyValue3 = [];

  return { propertyValue1, propertyValue2, propertyValue3 };
};

export const makeParamsFilters = (data = []) => {
  let filters = data
    .filter(v => v.value)
    .map(v => ({
      propertyName: v.name,
      propertyValue1: getFilterValue(v),
      propertyValue2: getFilterValue2(v),
      propertyValue3: [],
      filterDataType: 2,
      comparison: 2
    }));

  return JSON.stringify(filters);
};
