//import { getDomain } from 'services/generic.methods';
import packageJson from '../package.json';

const env = process.env;
// Jira FixVersion 1.0 & Date yyyymmdd e.g: 20210719 = 19-July-2021
const version = 'v-' + packageJson.version; // on every release we need to update this

const config = {
  development: {
    apiUrl: env.REACT_APP_APIURL,
    apiWCFUrl: env.REACT_APP_WCF_URL,
    reportUrl: env.REACT_APP_REPORT_URL,
    apiVersion: env.REACT_APP_API_VERSION,
    portalURL: env.REACT_APP_PORTAL_URL,
    allowConsoles: true,
    masterCompany: 'vendormax',
    environment: env.REACT_APP_ENVIRONMENT,
    scriptBotLink:
      env.REACT_APP_PORTAL_URL +
      'scripts/invitation.ashx?debug=true&company=' +
      env.REACT_APP_SCRIPTBOT_URL,
    version: env.REACT_APP_ENVIRONMENT + '-' + version,
    instrumentationConnectionString:
      env.REACT_APP_INSTRUMENTATION_CONNECTIONSTRING,
    apexchatIframeUrl: env.REACT_APP_APEXCHAT_IFRAME_URL,
    onRollIframeUrl: env.REACT_APP_ONROLL_IFRAME_URL,
    hideSectionSettingsForOwnerCompanyKeys:
      env.REACT_APP_HIDE_SECTION_SETTINGS_FOR_OWNER_COMPANY_KEYS,
    apexchatOwnerCompanyKeys: env.REACT_APP_APEXCHAT_OWNER_COMPANY_KEYS,
    apexchatURLS: env.REACT_APP_APEXCHAT_URLS,
    GATrackingId: env.REACT_APP_GA_TRACKING_ID,
    authCookieTimeoutInDays: env.REACT_APP_AUTH_COOKIE_TIMEOUT_IN_DAYS
  },
  test: {
    apiUrl: env.REACT_APP_APIURL,
    apiWCFUrl: env.REACT_APP_WCF_URL,
    reportUrl: env.REACT_APP_REPORT_URL,
    apiVersion: env.REACT_APP_API_VERSION,
    portalURL: env.REACT_APP_PORTAL_URL,
    allowConsoles: true,
    masterCompany: 'vendormax',
    environment: env.REACT_APP_ENVIRONMENT,
    scriptBotLink:
      env.REACT_APP_PORTAL_URL +
      'scripts/invitation.ashx?debug=true&company=' +
      env.REACT_APP_SCRIPTBOT_URL,
    version: env.REACT_APP_ENVIRONMENT + '-' + version,
    instrumentationConnectionString:
      env.REACT_APP_INSTRUMENTATION_CONNECTIONSTRING,
    apexchatIframeUrl: env.REACT_APP_APEXCHAT_IFRAME_URL,
    onRollIframeUrl: env.REACT_APP_ONROLL_IFRAME_URL,
    hideSectionSettingsForOwnerCompanyKeys:
      env.REACT_APP_HIDE_SECTION_SETTINGS_FOR_OWNER_COMPANY_KEYS,
    apexchatOwnerCompanyKeys: env.REACT_APP_APEXCHAT_OWNER_COMPANY_KEYS,
    apexchatURLS: env.REACT_APP_APEXCHAT_URLS,
    GATrackingId: env.REACT_APP_GA_TRACKING_ID,
    authCookieTimeoutInDays: env.REACT_APP_AUTH_COOKIE_TIMEOUT_IN_DAYS
  },
  staging: {
    apiUrl: env.REACT_APP_APIURL,
    apiWCFUrl: env.REACT_APP_WCF_URL,
    reportUrl: env.REACT_APP_REPORT_URL,
    apiVersion: env.REACT_APP_API_VERSION,
    portalURL: env.REACT_APP_PORTAL_URL,
    allowConsoles: true,
    masterCompany: 'vendormax',
    environment: env.REACT_APP_ENVIRONMENT,
    scriptBotLink:
      env.REACT_APP_PORTAL_URL +
      'scripts/invitation.ashx?debug=true&company=' +
      env.REACT_APP_SCRIPTBOT_URL,
    version: env.REACT_APP_ENVIRONMENT + '-' + version,
    instrumentationConnectionString:
      env.REACT_APP_INSTRUMENTATION_CONNECTIONSTRING,
    apexchatIframeUrl: env.REACT_APP_APEXCHAT_IFRAME_URL,
    onRollIframeUrl: env.REACT_APP_ONROLL_IFRAME_URL,
    hideSectionSettingsForOwnerCompanyKeys:
      env.REACT_APP_HIDE_SECTION_SETTINGS_FOR_OWNER_COMPANY_KEYS,
    apexchatOwnerCompanyKeys: env.REACT_APP_APEXCHAT_OWNER_COMPANY_KEYS,
    apexchatURLS: env.REACT_APP_APEXCHAT_URLS,
    GATrackingId: env.REACT_APP_GA_TRACKING_ID,
    authCookieTimeoutInDays: env.REACT_APP_AUTH_COOKIE_TIMEOUT_IN_DAYS
  },
  beta: {
    apiUrl: env.REACT_APP_APIURL,
    apiWCFUrl: env.REACT_APP_WCF_URL,
    reportUrl: env.REACT_APP_REPORT_URL,
    apiVersion: env.REACT_APP_API_VERSION,
    portalURL: env.REACT_APP_PORTAL_URL,
    allowConsoles: true,
    masterCompany: 'vendormax',
    environment: env.REACT_APP_ENVIRONMENT,
    scriptBotLink:
      env.REACT_APP_PORTAL_URL +
      'scripts/invitation.ashx?debug=true&company=' +
      env.REACT_APP_SCRIPTBOT_URL,
    version: env.REACT_APP_ENVIRONMENT + '-' + version,
    instrumentationConnectionString:
      env.REACT_APP_INSTRUMENTATION_CONNECTIONSTRING,
    apexchatIframeUrl: env.REACT_APP_APEXCHAT_IFRAME_URL,
    onRollIframeUrl: env.REACT_APP_ONROLL_IFRAME_URL,
    hideSectionSettingsForOwnerCompanyKeys:
      env.REACT_APP_HIDE_SECTION_SETTINGS_FOR_OWNER_COMPANY_KEYS,
    apexchatOwnerCompanyKeys: env.REACT_APP_APEXCHAT_OWNER_COMPANY_KEYS,
    apexchatURLS: env.REACT_APP_APEXCHAT_URLS,
    GATrackingId: env.REACT_APP_GA_TRACKING_ID,
    authCookieTimeoutInDays: env.REACT_APP_AUTH_COOKIE_TIMEOUT_IN_DAYS
  },
  /* Not yet decided */
  production: {
    apiUrl: env.REACT_APP_APIURL,
    apiWCFUrl: env.REACT_APP_WCF_URL,
    reportUrl: env.REACT_APP_REPORT_URL,
    apiVersion: env.REACT_APP_API_VERSION,
    portalURL: env.REACT_APP_PORTAL_URL,
    allowConsoles: false,
    masterCompany: 'vendormax',
    environment: env.REACT_APP_ENVIRONMENT,
    scriptBotLink:
      env.REACT_APP_PORTAL_URL +
      'scripts/invitation.ashx?debug=true&company=' +
      env.REACT_APP_SCRIPTBOT_URL,
    version: env.REACT_APP_ENVIRONMENT + '-' + version,
    instrumentationConnectionString:
      env.REACT_APP_INSTRUMENTATION_CONNECTIONSTRING,
    apexchatIframeUrl: env.REACT_APP_APEXCHAT_IFRAME_URL,
    onRollIframeUrl: env.REACT_APP_ONROLL_IFRAME_URL,
    hideSectionSettingsForOwnerCompanyKeys:
      env.REACT_APP_HIDE_SECTION_SETTINGS_FOR_OWNER_COMPANY_KEYS,
    apexchatOwnerCompanyKeys: env.REACT_APP_APEXCHAT_OWNER_COMPANY_KEYS,
    apexchatURLS: env.REACT_APP_APEXCHAT_URLS,
    GATrackingId: env.REACT_APP_GA_TRACKING_ID,
    authCookieTimeoutInDays: env.REACT_APP_AUTH_COOKIE_TIMEOUT_IN_DAYS
  }
};

export default config[env.NODE_ENV];
