import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
// import Home from 'pages/portal/home';
//import WidgetSetting from 'pages/portal/widget-setting';
import { lazy } from 'react';

const WidgetSettings = lazy(() => import('pages/portal/widget-setting'));
const Home = lazy(() => import('pages/portal/home'));
const generalRoutes = [
  {
    path: '/portal/settings/widget-editor',
    name: 'settings',
    title: 'Settings',
    component: props => <WidgetSettings {...props} />,
    icon: <UilSetting />,
    exact: true
  },
  {
    path: '/home',
    name: 'home',
    title: 'Home',
    component: props => <Home {...props} />,
    exact: true
  }
];

export default generalRoutes;
