import { UilQuestionCircle } from '@iconscout/react-unicons';
import AppInfoModal from 'pages/portal/modal/appInfoModal';
import { useState } from 'react';
import PopMenu, { PopMenuItem } from './popmenu';
import TourWrapper from './TourWrapper';

const HelpComp = ({ addScript, loading }) => {
  const [infoModal, setInfoModal] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  // const [infoModal, setInfoModal] = useState(false);

  const [toggleTour, setToggleTour] = useState(false);

  const toggelTourHandler = value => {
    setToggleTour(value);
  };

  // const useCloseOnClickedOutside = async ref => {
  //   await dispatch(stopPropogation(ref)).then(res => {});
  // };
  const launchInfoHandler = () => {
    setInfoModal(true);
  };
  const launchHandler = () => {
    setTimeout(() => {
      setModalShow(true);
    }, 1000);
  };

  return (
    <div>
      <PopMenu
        id="introJS-item-help"
        showSideBar={false}
        title={''}
        toolTipText={'Help'}
        menuHeading={'Help'}
        MenuIcon={'UilSetting'}
        icon={
          <UilQuestionCircle
            size="30"
            className="sidebar-link"
            color="var(--primary)"
          />
        }
        // clickHandler={e => history.push('/portal/settings/widget-editor')}
        position={{ left: 'auto', top: '38px' }}
      >
        {props => {
          return (
            <>
              <PopMenuItem
                text={`Support Bot `}
                handler={() => {
                  props.menuSet(false);
                  addScript();
                }}
                loading={
                  loading ? (
                    <i className="uil uil-spinner spinner ml-2"></i>
                  ) : (
                    ''
                  )
                }
              />
              <PopMenuItem
                text={'Launch Tour'}
                handler={() => {
                  props.menuSet(false);
                  launchHandler();
                }}
              />

              <PopMenuItem
                text={'App Info'}
                handler={() => {
                  props.menuSet(false);
                  launchInfoHandler();
                }}
              />
            </>
          );
        }}
      </PopMenu>

      {/* info modal */}
      <AppInfoModal
        showModal={infoModal}
        eventHideModal={() => {
          setInfoModal(false);
        }}
      />

      {/* tour modal */}
      <TourWrapper
        tourModalShow={modalShow}
        setModalShow={setModalShow}
        toggelTourHandler={toggelTourHandler}
        toggleTour={toggleTour}
        onTourChange={() => console.log('on tour change')}
      />
    </div>
  );
};

export default HelpComp;
