import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { getAllCompanies } from 'store/slices/companies/companiesSlice';
import storage from 'services/utils/storage';
import { useDispatch, useSelector } from 'react-redux';
import '@inovua/reactdatagrid-community/index.css';
import { widgetCompaniesTableConfig } from 'services/utils/data-grid/tableConfig';
import { toast } from 'react-toastify';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GridWrapper } from '../../../pub-components/GridWrapper';
import {
  getSelectedCompanies,
  setSelectedCompaniesToState
} from 'store/slices/widget-editor/widgetEditorSlice';
import DynamicColumns from '../report/dynamicColumns';
import { useTranslation } from 'react-i18next';
import useViewport from 'services/viewport-hook';
import { makeParamsFilters } from 'pub-components/GridWrapper/helper';
import { ToastTemplate } from 'services/utils/generic.methods';
export const dataKey = 'companies-widgetSetting';
// const tabKey = 'companies-widgetSetting-tab';
const useStyles = makeStyles(theme => ({
  companiesContainer: {
    display: ({ isMobile }) => (isMobile ? 'none' : 'block !important')
  }
}));
const Companies = ({ getCompaniesCount, refreshGrid, onCompanySelect }) => {
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedCompanies = useSelector(getSelectedCompanies);

  const [vissibleColumns, setVissibleColumns] = useState(null);
  const [selectedFilters] = useState(null);
  const screen = useViewport();
  const classes = useStyles({ isMobile: screen.xs });
  // initialize selected Tab Data after Redirect From other screen
  useEffect(() => {
    let data = JSON.parse(storage.getItem(dataKey)) || [];
    setSelectedData(generateSelected(data));
    dispatch(setSelectedCompaniesToState(data));
    if (data?.length) {
      // select company on reload for preview chat widget
      onCompanySelect(data[0]);
    }
  }, []);

  useEffect(() => {
    setRefetch(refreshGrid);
  }, [refreshGrid]);

  useEffect(() => {
    setSelectedData(generateSelected(selectedCompanies));
  }, [selectedCompanies]);

  // select Company
  const handleSelect = payload => {
    updateCompaniesAndSaveToLS(payload);

    // select company here for preview initially to load preview section
    if (payload?.data && !Array.isArray(payload?.data)) {
      let keys = Object.keys(payload?.selected);
      if (keys.includes(payload?.data?.id.toString())) {
        onCompanySelect(payload?.data);
      } else {
        let comp = payload?.selected[keys[keys.length - 1]];
        let forceEmpty = comp ? false : true;
        onCompanySelect(comp, forceEmpty);
      }
    } else if (
      payload?.data &&
      Array.isArray(payload?.data) &&
      payload?.data?.length
    ) {
      onCompanySelect(payload?.data[0]);
    } else if (payload?.data && !payload?.data?.length) {
      let forceEmpty = true;
      onCompanySelect(null, forceEmpty);
    }
  };

  //  update Selected Companies and save to Local Storage
  const updateCompaniesAndSaveToLS = payload => {
    const { data, selected } = payload;
    let temp =
      typeof selected == 'boolean'
        ? data
        : Object.keys(selected).map(key => ({ ...selected[key] }));

    setSelectedData(
      typeof selected == 'boolean' ? generateSelected(data) : selected
    );
    dispatch(setSelectedCompaniesToState(temp));
  };

  const generateSelected = arrayData => {
    return arrayData.reduce(
      (obj, item) => ({
        ...obj,
        [item['id']]: item
      }),
      {}
    );
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setLoadingMessage('Loading ...');
      const updatedValue = makeParamsFilters(filterValue);
      let payload = {
        start: skip,
        limit: limit,
        filter: updatedValue,
        dir: !sortInfo ? 'DESC' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
        sort: sortInfo ? sortInfo.name : 'createdOn'
      };
      return getAllCompanies(payload).then(response => {
        if (response.data.totalSize > 20) {
          setDataLimit(skip + response.data.data.length);
        } else {
          setDataLimit(response.data.data.length);
        }
        const totalCount = response.data.totalSize;

        setTotalRecords(totalCount);
        if (!JSON.parse(updatedValue).length) {
          getCompaniesCount(totalCount);
          if (totalCount === 1) {
            let singleCompany = response.data.data[0];
            updateCompaniesAndSaveToLS({
              selected: { [singleCompany.id]: singleCompany }
            });
            // select company for preview panel
            onCompanySelect(singleCompany);
          }
        }
        return { data: response.data.data, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  const dataSource = useCallback(loadData, []);
  const [dataLimit, setDataLimit] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');

  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  const handleColumnsChange = data => {
    setVissibleColumns({ ...data });
  };
  return (
    <>
      <div
        className={`companies-container pr-2 pl-2 ${classes.companiesContainer}`}
      >
        <Row>
          <Col className="m-3">
            <h2>
              {t('widget.widget_companies')}
              <Badge variant="primary p-1 badge-align">
                {loading ? '...' : `${dataLimit} / ${totalRecords}`}
              </Badge>
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className={loading && 'blux-text'}>
            <div className="mobile_view">
              <GridWrapper
                refetch={refetch}
                checkboxOnlyRowSelect={true}
                checkboxColumn={true}
                customGridStyle={{ minHeight: 'calc(100vh - 170px)' }}
                columns={widgetCompaniesTableConfig()}
                totalRecords={totalRecords}
                dataSource={dataSource}
                setLoading={setLoading}
                loadingMessage={loadingMessage}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
                handleSelect={handleSelect}
                vissibleColumns={vissibleColumns}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(Companies);
