import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { UserAPI } from 'api/endpoints';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const usersSlice = createSlice({
  name: 'user',
  initialState: {
    user: {}
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setUserToState: (state, action) => {
      state.user = action.payload.data;
    }
  }
});

export const { setUserToState } = usersSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/
export const getUserAsync = payload => (dispatch, getState) => {
  let userAPI = UserAPI.get + '/' + payload.params.id;
  return ApiServices.getWithParams(userAPI, payload)
    .then(async ({ data }) => {
      if (data?.success && data?.data) {
        if (dispatch) await dispatch(setUserToState(data));
        return Promise.resolve(data);
      }
      return Promise.reject('rejected');
    })
    .catch(error => {
      return Promise.reject(error);
    });
  // }
};

export const uploadUserImage = (payload, id) => {
  let userAPI = UserAPI.uploadImage(id);
  return ApiServices.postAttachements(userAPI, payload);
};

export const updateUser = payload => {
  let userAPI = `${UserAPI.put}/${payload.id}`;
  return ApiServices.put(userAPI, payload);
};

export const getUserById = payload => {
  let userAPI = UserAPI.get + '/' + payload.params.id;
  return ApiServices.getWithParams(userAPI, payload);
  // }
};
/* called a selector and allows to select values from the state */
export const selectUser = state => {
  return state.user;
};
// Checks the SSO users table to see if an SSO user is linked to more than one company
export const getUserHasMultipleCompaniesAsync = payload => {
  let userAPI = UserAPI.getUserHasMultipleCompanies + '/' + payload.params.id;
  return ApiServices.getWithParams(userAPI, payload).then(async ({ data }) => {
    if (data?.success && data?.data) {
      return Promise.resolve(data);
    }
    return Promise.reject('rejected');
  })
  .catch(error => {
    return Promise.reject(error);
  });
};
export const getUsersByCompany = payload => {
  let userAPI = `${UserAPI.getAll}/${payload.companyId}`;
  return ApiServices.getWithParams(userAPI, { params: payload });
};

export default usersSlice.reducer;
