import React, { useRef, useState } from 'react';
import { Button, Overlay, Form, OverlayTrigger } from 'react-bootstrap';
import { UilGrids, UilFileExport, UilImport } from '@iconscout/react-unicons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { MenuItem } from '@material-ui/core';
import {
  getTimeDifference,
  JSONToCSVFileConvertor,
  ToastTemplate,
  toolTipPlacement
} from 'services/utils/generic.methods';
import moment from 'moment';
import renderToolTip from 'pub-components/ui-controls/renderToolTip';
import './dynamicColumns.scss';
import Tracker from 'app/tracking/tracking-service';
import { Enums } from 'services/dropdown-enums';
// import { selectGridColumns, setGridColumns } from 'features/state/stateSlice';
import { useEffect } from 'react';
import ScrollBar from 'pub-components/Scrollbars';

const initialColumns = (data = []) => {
  let obj = {};
  data.forEach(val => {
    obj[val.name] = val.visible ? true : false;
  });
  return obj;
};
const DynamicColumns = ({
  menuOptions,
  handleChange,
  hideColumns = ['actions'],
  selectedFilters,
  isExport = true,
  endPointAPI,
  fileNamePrefix = '',
  storageKey = '',
  customStyles = {
    minHeight: '80px',
    height: 'calc(100vh - 300px)'
  }
}) => {
  // const columns = useSelector(selectGridColumns);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [options, setOptions] = useState(
    // columns[storageKey] ||
    initialColumns(menuOptions)
  );
  const target = useRef(null);
  const targetExport = useRef(null);
  const { t } = useTranslation();

  const handleOnChange = optionKey => {
    let temp = { ...options };
    temp[optionKey] = !temp[optionKey];
    setOptions(temp);
  };

  useEffect(() => {
    applyColumns();
  }, []);

  useEffect(() => {
    setOptions(initialColumns(menuOptions));
    handleChange(initialColumns(menuOptions));
  }, [menuOptions.length]);

  const applyColumns = () => {
    if (storageKey) {
      // dispatch(setGridColumns({ [storageKey]: options }));
    }

    handleChange(options);
    setShow(false);
  };
  const handleSelectAll = check => {
    let temp = { ...options };
    Object.keys(temp).forEach(key => {
      if (!hideColumns.includes(key)) temp[key] = check;
    });
    setOptions(temp);
  };

  const checkSelectAllOrNone = (condition = 'every') => {
    return Object.keys(options)
      .filter(key => !hideColumns.includes(key))
      [condition](val => options[val]);
  };

  const onExport = (isCurrentPage = false) => {
    let payload = { ...selectedFilters };
    if (!isCurrentPage) {
      delete payload['limit'];
    }
    GetDataFromAPI(payload);
  };

  // call tracker event
  const callTrackerEvent = (type, label, value) => {
    Tracker.EventsProvider(Enums.TrackerType.GoogleAnalytics).Export.Publish(
      `Export ${fileNamePrefix}`,
      type,
      label,
      value
    );
  };

  const GetDataFromAPI = payload => {
    try {
      // call tracker event for click export
      let isExportAll = payload['limit'] ? false : true;
      let timeEndCallback = getTimeDifference();
      let eventLabel = `${
        !isExportAll
          ? 'current page records limit:' + payload['limit']
          : 'all records'
      }`;
      let eventValue = `${isExportAll ? 'All' : payload['limit']}`;
      let type = `Export ${isExportAll ? 'All' : 'Page'}`;
      callTrackerEvent(type, eventLabel, eventValue);

      setLoading(true);
      endPointAPI(payload).then(response => {
        let fileName = `${fileNamePrefix}-export-${moment().format(
          'YYYY-MM-DD'
        )}`;
        JSONToCSVFileConvertor(
          response?.data?.data,
          fileName,
          menuOptions.filter(dc => !!options[dc.name])
        );
        setLoading(false);
        //  call tracker event for download
        let result = timeEndCallback();
        let downloadLabel = `${eventLabel} | ${fileName} | download time is ${result}sec`;
        callTrackerEvent('Download', downloadLabel, eventValue);
      });
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  return (
    <>
      {/* dynamic column */}
      <OverlayTrigger
        placement={toolTipPlacement}
        overlay={renderToolTip('Columns')}
      >
        <Button
          className="btn-sm btn-flex"
          variant="lightgray-3"
          ref={target}
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
          onClick={() => setShow(!show)}
        >
          <UilGrids size="18" style={{ width: 'auto', height: 'auto' }} />
        </Button>
      </OverlayTrigger>

      <Overlay
        target={target.current}
        show={show}
        placement="bottom"
        onHide={() => setShow(false)}
        rootClose={true}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            id="dynamicColumns"
            {...props}
            style={{ ...props.style, width: '220px' }}
            className="dynamic-col-container popover-style"
          >
            <Form.Check
              custom
              type="checkbox"
              name="checkbox"
              id="selectAllOptions"
              label={t('company.analytics.selectAll')}
              onChange={e => handleSelectAll(e.target.checked)}
              checked={checkSelectAllOrNone('every')}
              className="check-box-all"
            />
            <ScrollBar
              horizontalScroll={false}
              className="custom-scrollbar"
              style={customStyles}
            >
              {menuOptions.map((mo, index) =>
                !hideColumns.includes(mo.name) ? (
                  <Form.Check
                    key={index}
                    custom
                    type="checkbox"
                    name="checkbox"
                    id={mo.name + index}
                    label={mo.header}
                    onChange={e => handleOnChange(mo.name)}
                    checked={options[mo.name]}
                    className="check-box"
                  />
                ) : null
              )}
            </ScrollBar>
            <Button
              className="btn-apply-filter"
              variant="primary"
              title={t('company.analytics.filters')}
              disabled={!checkSelectAllOrNone('some')}
              onClick={applyColumns}
            >
              {t('company.analytics.apply')}
            </Button>
          </div>
        )}
      </Overlay>

      {/* End */}

      {/* export options */}

      {isExport ? (
        <OverlayTrigger
          placement={toolTipPlacement}
          overlay={renderToolTip('Export')}
        >
          <Button
            className=" btn-sm btn-flex"
            variant="lightgray-3"
            ref={targetExport}
            onClick={() => setShowExport(!showExport)}
            label="Export"
            style={{ padding: '10px' }}
            disabled={loading}
          >
            {loading ? (
              <i
                className="uil uil-spinner spinner"
                style={{ fontSize: '16px' }}
              ></i>
            ) : (
              <UilFileExport
                size="18"
                style={{ width: 'auto', height: 'auto' }}
              />
            )}
          </Button>
        </OverlayTrigger>
      ) : null}

      <Overlay
        target={targetExport.current}
        show={showExport}
        placement="bottom"
        onHide={() => setShowExport(false)}
        rootClose={true}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={props.style}
            className="export-container cursor-pointer popover-style"
          >
            <MenuItem className="menu-option" onClick={e => onExport(false)}>
              <UilImport size="15" className="mr-1" />
              Export All
            </MenuItem>
            <MenuItem className="menu-option" onClick={e => onExport(true)}>
              <UilFileExport size="15" className="mr-1" />
              Export Page
            </MenuItem>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default DynamicColumns;
