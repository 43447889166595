import React, { useState } from 'react';
import portalRoutes from 'routes/portalRoutes';
import SubMenu from './SubMenu';
import SideBarBottom from './SideBarBottom';
import Navbar from 'react-bootstrap/Navbar';
import { breakpoints } from 'services/utils/variables';
import { ActiveLink } from 'pub-components/link/Link';
import storage from 'services/utils/storage';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoles } from 'store/slices/roles/rolesSlice';
import RenderSidebarTooltip from 'pub-components/ui-controls/render-sidebar-tooltip';
import { setNavbarStatus } from 'store/slices/sidebar-nav/sidebarNavSlice';
import { useEffect } from 'react';
import useViewport from 'services/viewport-hook';
import './styles.scss';
import { Button, Modal } from 'react-bootstrap';
import { globalLogout } from 'services/utils/generic-logout-method';
import { isAndroid } from 'react-device-detect';

const Sidebar = ({
  showSidebar,
  setShowSidebar,
  showLimitedAccessModal,
  limitedAccessModalHandler
}) => {
  const user = JSON.parse(storage.getItem('Profile'));
  const { roles } = useSelector(selectRoles);
  const dispatch = useDispatch();
  const switchToPermission = (roles && roles.switch) || {};
  const deviceWidth =
    window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  const [selectedSubmMenu, setSelectedSubmMenu] = useState({});
  const [subnav, setSubnav] = useState(false);
  const screen = useViewport();

  const showSidebarHandler = () => {
    // dispatch(setNavbarStatus(!showSidebar));
    setShowSidebar(!showSidebar);
  };
  useEffect(() => {
    dispatch(setNavbarStatus(showSidebar));
  }, [showSidebar]);

  const showSubnav = selectedSubMenu => {
    if (deviceWidth <= breakpoints.tabletAndSmallerDevices) {
      showSidebarHandler();
    }
    setSubnav(!!selectedSubMenu?.subNav?.length);
    setSelectedSubmMenu(selectedSubMenu);
  };

  return (
    <Navbar
      id="sidebar"
      className={
        'sidebar-nav sidebar-nav-' +
        (screen?.xs && showSidebar ? 'open active' : 'close')
      }
      style={isAndroid ? { padding: '8.5rem 0 0' } : { padding: '5.5rem 0 0' }}
    >
      {/* {screen?.xs && (
        <a href="#" className="navbar-ham" onClick={showSidebarHandler}>
          {showSidebar ? (
            <UilAngleLeft size="28" color={'var(--primary)'} />
          ) : (
            <UilAngleRight size="28" color={'var(--primary)'} />
          )}
        </a>
      )} */}
      <div className="sidebar-wrapper">
        {/* portal design UIUX-1378 commented logo */}
        {/* <Link className="navbar-logo d-block" to="/home">
          <Logo />
        </Link> */}
        {/* <ScrollBar
          horizontalScroll={false}
          className="scrollbar-wrap"
          style={{
            width: '100%',
            height: 'calc(100% - 135px)'
          }}
          renderThumbVertical={() => <div className="scrollbar-rail" />}
        >*/}
        <div className="customized-scrollbar">
          <div className="customized-scrollbar-inner">
            <div className="sidebar-primary bg-primary">
              <ul>
                {roles &&
                  Object.keys(roles).length &&
                  portalRoutes.map((item, index) => {
                    let permission = roles[item.name] || roles.messaging;
                    return (
                      <li key={'item-' + index}>
                        {permission &&
                        permission.displayOnly &&
                        permission.view ? (
                          <ActiveLink
                            id={'introJS-item-' + index}
                            activeClassName="active-link"
                            onClick={() => limitedAccessModalHandler()}
                            disabledlink={true}
                          >
                            <div
                              className={`sidebar-link sidebar-link-with-below-text`}
                            >
                              <RenderSidebarTooltip
                                showSidebar={showSidebar}
                                title={item.title}
                                icon={item.icon}
                                showTooltip={false}
                              />
                              <span className={'link-text-below-icon'}>
                                {item.title}
                              </span>
                            </div>
                          </ActiveLink>
                        ) : item.path && permission && permission.view ? (
                          <ActiveLink
                            id={'introJS-item-' + index}
                            activeClassName="active-link"
                            href={item.path}
                            onClick={() => showSubnav(item)}
                          >
                            <div
                              className={`sidebar-link sidebar-link-with-below-text`}
                            >
                              <div
                                className={`sidebar-link sidebar-link-with-below-text`}
                              >
                                <RenderSidebarTooltip
                                  showSidebar={showSidebar}
                                  title={item.title}
                                  icon={item.icon}
                                  showTooltip={false}
                                />
                                <span className={'link-text-below-icon'}>
                                  {item.title}
                                </span>
                              </div>
                            </div>
                          </ActiveLink>
                        ) : permission && permission.allowMessaging ? (
                          <ActiveLink
                            id={'introJS-item-' + index}
                            activeClassName="active-link"
                            href={
                              roles?.showLimitedAccessWarning
                                ? item.path + window.location.search
                                : item.path
                            }
                            forceselected={!!roles?.showLimitedAccessWarning}
                            onClick={() => showSubnav(item)}
                          >
                            <div
                              className={`sidebar-link sidebar-link-with-below-text`}
                            >
                              <RenderSidebarTooltip
                                showSidebar={showSidebar}
                                title={item.title}
                                icon={item.icon}
                                showTooltip={false}
                              />
                              <span className={'link-text-below-icon'}>
                                {item.title}
                              </span>
                            </div>
                          </ActiveLink>
                        ) : null}
                      </li>
                    );
                  })}
              </ul>
            </div>
            {subnav && (
              <div className="sidebar-secondary bg-lightgray-2">
                <SubMenu item={selectedSubmMenu} />
              </div>
            )}
          </div>
        </div>
        {/* </ScrollBar> */}
      </div>
      <SideBarBottom
        user={user}
        showSideBar={showSidebar}
        switchTo={switchToPermission}
        setShowSidebar={setShowSidebar}
      />
      <Modal
        show={showLimitedAccessModal}
        // onHide={eventHideModal}
        size="md"
        keyboard={false}
        id="passwordModal"
        dialogClassName="passwordModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // onEnter={onEnterCallback}
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter">
            You have limited access
          </Modal.Title>
          <p>
            To gain full access of the dashboard, use your credentials to login.
          </p>
        </Modal.Body>
        <Modal.Footer className="user-modal-footer">
          <Button
            type="button"
            variant="outline-danger"
            className="btn-md btn-flex float-right"
            onClick={() => globalLogout()}
          >
            Login
          </Button>
          <Button
            type="button"
            variant="outline-primary"
            className="passBtn btn-md btn-flex float-right"
            onClick={() => limitedAccessModalHandler()}
          >
            <span>Close</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </Navbar>
  );
};

export default Sidebar;
