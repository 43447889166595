import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  FormGroupField,
  FormGroupToggleField
} from '../../FormGroupInputFields';

function UserForm({
  userFormIk,
  fieldErrorOnSubmit,
  handleOnChange,
  ownProfile
}) {
  return (
    <Form
      className="form form-class white-background"
      onSubmit={userFormIk.handleSubmit}
      autoComplete="off"
    >
      <Row>
        <Col md={6}>
          <FormGroupField
            controlId="userId"
            label="User ID"
            handleOnChange={handleOnChange}
            dataFormIk={userFormIk}
            fieldErrorOnSubmit={fieldErrorOnSubmit}
          />
        </Col>
        <Col md={6}>
          <FormGroupField
            controlId="userName"
            label="User Name"
            handleOnChange={handleOnChange}
            dataFormIk={userFormIk}
            fieldErrorOnSubmit={fieldErrorOnSubmit}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroupField
            controlId="displayName"
            label="Display Name"
            handleOnChange={handleOnChange}
            dataFormIk={userFormIk}
            fieldErrorOnSubmit={fieldErrorOnSubmit}
            disabled={!ownProfile}
          />
        </Col>
        <Col md={6}>
          <FormGroupField
            controlId="email"
            label="Email"
            handleOnChange={handleOnChange}
            dataFormIk={userFormIk}
            fieldErrorOnSubmit={fieldErrorOnSubmit}
            disabled={!ownProfile}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroupField
            controlId="userType"
            label="User Type"
            handleOnChange={handleOnChange}
            dataFormIk={userFormIk}
            fieldErrorOnSubmit={fieldErrorOnSubmit}
          />
        </Col>
        <Col md={6}>
          <FormGroupToggleField
            controlId="lockedOut"
            label="Locked Out"
            handleChecked={handleOnChange}
            dataFormIk={userFormIk}
            fieldErrorOnSubmit={fieldErrorOnSubmit}
            disabled
          />
        </Col>
      </Row>
      {/* <Row>
        <Col md={6}>
          <FormGroupToggleField
            controlId="preferMobile"
            label="Prefer Mobile"
            handleChecked={handleOnChange}
            dataFormIk={userFormIk}
            fieldErrorOnSubmit={fieldErrorOnSubmit}
            disabled
          />  
        </Col>
      </Row> */}
    </Form>
  );
}

export default UserForm;
