import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilKeySkeleton from '@iconscout/react-unicons/icons/uil-key-skeleton';
import { UilUpload } from '@iconscout/react-unicons';
import { theme } from 'services/utils/variables';
import {
  setLanguage,
  stopPropogation
} from 'store/slices/general/generalSlice';
import i18n from 'app/i18n';
import ChangePassword from 'pages/portal/modal/ChangePassword';
import SimpleSelectBox from 'pub-components/ui-controls/ui-components/SimpleSelectBox';
import UserModal from 'pages/portal/modal/UserModal';
import storage from 'services/utils/storage';
import {
  getUserAsync,
  getUserHasMultipleCompaniesAsync,
  selectUser
} from 'store/slices/users/userSlice';
import { useTranslation } from 'react-i18next';
import RenderSidebarTooltip from 'pub-components/ui-controls/render-sidebar-tooltip';
import { globalLogout } from 'services/utils/generic-logout-method';
import { changeThemeColor } from './helper';
import config from 'config';
import alterImage from 'assets/app-images/alter_image.png';
import TruncateWithToolTip from 'pub-components/TruncateWithToolTip';
import SSO_Redirection from 'app/redirection-SSO';
import UilDirection from '@iconscout/react-unicons/icons/uil-direction';
import { selectRoles } from 'store/slices/roles/rolesSlice';
import { selectDynamicMeta } from 'store/slices/auth/authSlice';

const ProfileNav = ({ showSideBar, onLogOutRemoveChat }) => {
  const user = JSON.parse(storage.getItem('Profile'));
  const _dynamicMeta = useSelector(selectDynamicMeta);
  const [menuShow, setMenuShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCompanySelector, setShowCompanySelector] = useState(false);
  const [selectedTab, setSelectedTab] = useState('userDetails');
  const [showProfileModal, setShowProfileModal] = useState(false);
  const profile = useSelector(selectUser).user;
  const [selectedTheme, setSelectedTheme] = useState('gray');
  const { t } = useTranslation();
  const [userImageUrl, setUserImageUrl] = useState(user?.ImageUrl);
  const dispatch = useDispatch();
  const { roles } = useSelector(selectRoles);
  const languageOptions = [
    { value: 'en-US', text: 'English' },
    { value: 'sp-SP', text: 'Spanish' },
    { value: 'fr-FR', text: 'French' }
  ];

  const themeOptions = [
    { value: 'blue', text: 'OnRoll' },
    { value: 'gray', text: 'Apexchat' },
    { value: 'dark-grey', text: 'Dark Grey' },
    { value: 'turq-green', text: 'Turquoise Green' },
    { value: 'eco-green', text: 'Eco Green' },
    { value: 'orange', text: 'Orange' }
  ];

  const allowedEnvironmentForThemeSelectionDropdown = ['production', 'beta'];
  const localStorageLanguage = JSON.parse(storage.getItem('language'));
  const logoutHandler = () => {
    onLogOutRemoveChat();
    globalLogout();
  };

  const changeCompanyHandler = () => {
    SSO_Redirection({ isCompanyChangeRedirect: true });
  };

  const shouldShowCompanyChangeButton = async () => {
    // SSO-222 on reload change company option is not visible
    let isSSOEnabled = _dynamicMeta?.ssoEnabled;
    if (isSSOEnabled) {
      let profile = JSON.parse(storage.getItem('Profile'));
      let result = await getUserHasMultipleCompaniesAsync({
        params: { id: profile?.UserID }
      });
      if (result && result.success && result.data) {
        setShowCompanySelector(result.data);
      }
    }
  };

  const useCloseOnClickedOutside = async ref => {
    await dispatch(stopPropogation(ref)).then(res => {
      if (res) setMenuShow(false);
    });
  };

  // use ref on wrapper element
  const wrapperRef = useRef(null);
  // calling the method to check if the clicked event
  // happened inside / outside the wrapper
  // and pass the wrapper ref
  useCloseOnClickedOutside(wrapperRef);

  const toggleDropdownHandler = e => {
    let show = !menuShow;
    setMenuShow(show);
  };
  const languageHandler = (el, field, language) => {
    let tempLanguage;
    if (language) {
      tempLanguage = language;
      setMenuShow(false);
    } else {
      tempLanguage = localStorageLanguage;
    }
    i18n.changeLanguage(tempLanguage);
    dispatch(setLanguage(tempLanguage));
  };

  const openCloseProfileModal = () => {
    let showModalState = !showProfileModal;
    setShowProfileModal(showModalState);
    if (showModalState)
      dispatch(
        getUserAsync({
          params: { id: user.UserID }
        })
      );
    setUserImageUrl(user.ImageUrl);
    setMenuShow(false);
  };

  const themeHandler = (el, field, theme) => {
    changeThemeColor(theme);
    setSelectedTheme(theme);
  };

  useEffect(async () => {
    languageHandler();
    await shouldShowCompanyChangeButton();
  }, [profile, _dynamicMeta?.ssoEnabled, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?.RequirePasswordReset) {
      setSelectedTab('changePassword');
      dispatch(
        getUserAsync({
          params: { id: user.UserID }
        })
      );
      setUserImageUrl(user.ImageUrl);
      setShowProfileModal(true);
    }
  }, [user?.RequirePasswordReset]);

  const onErrorImageUrl = e => {
    setUserImageUrl(alterImage);
  };

  return (
    <div className="profile-nav" ref={wrapperRef}>
      <div
        className="avatar-nav d-block"
        onClick={e => toggleDropdownHandler(e)}
      >
        {/* conditional toggle avtar/usericon */}
        <div className="userIcon d-flex align-items-center ">
          {user?.ImageUrl ? (
            <RenderSidebarTooltip
              showSidebar={showSideBar}
              title={user?.DisplayName}
              icon={
                <img
                  src={userImageUrl}
                  className="avatar-styles"
                  alt="user"
                  onError={onErrorImageUrl}
                />
              }
            />
          ) : (
            <RenderSidebarTooltip
              showSidebar={showSideBar}
              title={user?.DisplayName}
              icon={
                <img src={alterImage} className="avatar-styles" alt="user" />
              }
              // icon={<UilUser size="16" color="white" className="avatar-icon" />}
            />
          )}
          {showSideBar ? (
            <span className="userText fw-600">
              {user?.DisplayName ? user.DisplayName : ''}
            </span>
          ) : null}
        </div>
      </div>
      {/* toggle the menu on condition check */}
      {menuShow && (
        <div
          className={`sidebar-bottom-menu${showSideBar ? '-open' : ''} `}
          style={{ width: '15rem', top: '52px' }}
        >
          <ul className="dropdow-list">
            <li className="notification-list-item d-flex m-0 p-2 align-items-center">
              <RenderSidebarTooltip
                showSidebar={showSideBar}
                title={user?.DisplayName}
                icon={
                  <img
                    src={userImageUrl || ''}
                    className="avatar-styles"
                    alt="user"
                    onError={onErrorImageUrl}
                  />
                }
              />
              <div className="ml-2">
                <div>{user?.DisplayName}</div>
                <TruncateWithToolTip
                  text={user?.Email}
                  tooltip={user?.Email?.length > 17}
                  limit={17}
                />
                {/* <div></div> */}
              </div>
            </li>
            {roles?.profileNav?.portalLanguage && (
              <li className="notification-list-item">
                <div className="select-title py-2">
                  {t('company.general.portallang')}
                </div>
                <SimpleSelectBox
                  dataProperty={localStorageLanguage || ''}
                  options={languageOptions}
                  handleChange={languageHandler}
                  customStyle={{
                    fontSize: '0.8125rem',
                    color: 'var(--black)',
                    height: '33px'
                  }}
                />
                {/* <select
                className="form-control select"
                value={language}
                onChange={e => languageHandler(e.target.value)}
              >
                <option value="en-US">English</option>
                <option value="sp-SP">Spanish</option>
                <option value="fr-FR">French</option>
              </select> */}
              </li>
            )}

            {!allowedEnvironmentForThemeSelectionDropdown.includes(
              config.environment
            ) &&
              roles?.profileNav?.theme && (
                <li className="notification-list-item">
                  <div className="select-title py-2">Theme</div>
                  <SimpleSelectBox
                    dataProperty={selectedTheme}
                    options={themeOptions}
                    handleChange={themeHandler}
                    customStyle={{
                      fontSize: '0.8125rem',
                      color: 'var(--black)',
                      height: '33px'
                    }}
                  />
                </li>
              )}
            {roles?.profileNav?.profileView && (
              <li className="notification-list-item">
                <a
                  href="#"
                  style={{ color: 'var(--primary)' }}
                  onClick={openCloseProfileModal}
                >
                  <UilUser
                    size="16"
                    color={'var(--primary)'}
                    className="list-icon"
                  />
                  {t('company.general.profile')}
                </a>
              </li>
            )}
            {/* {roles?.profileNav?.changePassword && (
              <li className="notification-list-item">
                <a
                  href="#"
                  style={{ color: 'var(--primary)' }}
                  onClick={openModal}
                >
                  <UilKeySkeleton
                    size="16"
                    color={'var(--primary)'}
                    className="list-icon"
                  />
                  {t('company.general.changePass')}
                </a>
              </li>
            )} */}
            {/* <li className="notification-list-item">
              <a
                href="#"
                style={{ color: theme.danger }}
                onClick={redirectHandler}
              >
                <UilSignout
                  size="16"
                  color={theme.danger}
                  className="list-icon"
                />
                Redirect to Old Portal
              </a>
            </li> */}
            {showCompanySelector && !roles?.showLimitedAccessWarning && (
              <li className="notification-list-item">
                <a
                  href="#"
                  style={{ color: 'var(--primary)' }}
                  onClick={changeCompanyHandler}
                >
                  <UilDirection
                    size="16"
                    color={'var(--primary)'}
                    className="list-icon"
                  />
                  {t('company.general.changeCompany') +
                    ' (' +
                    user?.CompanyKey +
                    ')'}
                </a>
              </li>
            )}
            <li className="notification-list-item">
              <a
                style={{ color: theme.danger, cursor: 'pointer' }}
                onClick={logoutHandler}
              >
                <UilUpload
                  size="16"
                  color={theme.danger}
                  className="list-icon"
                  style={{ transform: 'rotate(90deg)' }}
                />
                {t('company.general.logout')}
              </a>
            </li>
          </ul>
        </div>
      )}
      <UserModal
        title={'User Information'}
        eventHideModal={() => setShowProfileModal(false)}
        showModal={showProfileModal}
        editData={profile}
        setProfileImage={setUserImageUrl}
        defaultTab={selectedTab}
        logoutHandler={logoutHandler}
      />
      {/* <ChangePassword
        showModal={showModal}
        eventHideModal={closeModal}
        requirePasswordReset={user && user.RequirePasswordReset}
      /> */}
    </div>
  );
};

export default ProfileNav;
