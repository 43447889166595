import { camelCaseStringToNormalText } from './utils/generic.methods';

export const TimeIntervalsEnum = {
  Day: 1,
  Week: 2,
  Month: 3,
  SixMonths: 4,
  Year: 5
};
export const FieldsEnum = {
  Name: 1,
  Email: 2,
  Webhook: 3,
  Time_zone: 4,
  Period: 5,
  Add_Report_Attachment: 6,
  Group_By_Company: 7,
  Company_Keys: 8,
  Hosts: 9,
  Aggregate: 10,
  Group: 11,
  Start_Date: 12,
  End_Date: 13,
  Agent_Id: 14,
  Agent_Operator_Filter: 15,
  Minimum: 16,
  Maximum: 17,
  Pause_Count: 18,
  Threshold: 19,
  Picked_Up_By_Username: 20,
  Limit: 21
};

export const GroupByEnums = {
  minute: 1,
  hour: 2,
  day: 3,
  month: 4,
  year: 5
};

const dropdownEnums = {
  /*createEnum takes an Array of object having property "value" & "name" and creates Enum
  example: createEnum([{ value: 1, name: 'enum1' },{ value: 2, name: 'enum2' }])
  Reason behind this effort is we need to have the Enum for dropdown which requies .map for rendering,
  we are using the dropdown_enums and generating Enums so avoiding duplications.*/
  createEnum: array => {
    return Object.freeze(
      array.reduce((obj, props) => {
        if (props && typeof props.name === 'string') {
          obj[props.name] = props.value;
        }
        return obj;
      }, {})
    );
  },

  enum_UserType: [
    { value: 1, name: 'Default' },
    { value: 2, name: 'Agent', key: 'AssignToAgents' },
    { value: 3, name: 'Operator', key: 'OperatorsEnabled' },
    { value: 4, name: 'Virtual Assistant' },
    { value: 5, name: 'Script Bot Assistant', key: 'ScriptBotEnabled' }
  ],
  enum_Relationship: [
    { value: 1, name: 'Customer' },
    { value: 2, name: 'Partner' },
    { value: 3, name: 'Administrator' }
  ],
  enum_LogLevel: [
    { value: 1, name: 'Debug' },
    { value: 2, name: 'Warn' },
    { value: 3, name: 'Error' }
  ],
  enum_ChatFlowEnableFlag: [
    { value: 0, name: 'Disabled' },
    { value: 1, name: 'Auto_Send' },
    { value: 2, name: 'Suggest_Agent' }
  ],
  enum_CompanyType: [
    { value: 1, name: 'Default' },
    { value: 2, name: 'Dealership' }
  ],
  enum_PacingType: [
    { value: 1, name: 'Leads' },
    { value: 2, name: 'Traffic' }
  ],
  enum_AnalyticsTracking: [
    { value: 1, name: 'None' },
    { value: 2, name: 'GoogleUniversal' },
    { value: 3, name: 'FacebookPixel' },
    { value: 4, name: 'Custom' },
    { value: 5, name: 'GoogleAnalyticsClientId' },
    { value: 6, name: 'GoogleAnalyticsCrossDomainTracking' },
    { value: 7, name: 'GoogleAnalytics4' }
  ],
  enum_LeadType: [
    { value: 1, name: 'Sales' },
    { value: 2, name: 'Service' },
    { value: 3, name: 'Other' },
    { value: 4, name: 'All' }
  ],
  enum_InitiatedBy: [
    // { value: 0, name: 'Unknown', selectorType: 'web' },
    { value: 1, name: 'Bar', key: 'ChatbarEnabled', selectorType: 'web' },
    // { value: 2, name: 'Dom', selectorType: 'web' },
    // { value: 3, name: 'Window', selectorType: 'web' },
    { value: 4, name: 'Static', key: 'Static', selectorType: 'web' },
    {
      value: 5,
      name: 'Standard',
      key: 'StandardInvitationEnabled',
      selectorType: 'web'
    },
    { value: 6, name: 'Tab', key: 'ChatTabEnabled', selectorType: 'web' },
    {
      value: 7,
      name: 'Exit Popup',
      key: 'ExitPopupEnabled',
      selectorType: 'web'
    },
    {
      value: 8,
      name: 'Sms Static',
      key: 'StaticSmsEnabled',
      selectorType: 'sms'
    },
    {
      value: 9,
      name: 'Sms Container',
      key: 'SmsContainer',
      selectorType: 'sms'
    },
    {
      value: 10,
      name: 'Facebook',
      key: 'EnableFacebookIntegration',
      selectorType: 'social'
    },
    // { value: 11, name: 'Video' },  this option is removed as per requirement in ticket UIUX-646
    { value: 12, name: 'PreChat', key: 'PreChatEnabled', selectorType: 'web' },
    { value: 13, name: 'Sms Web', key: 'SMSEnabled', selectorType: 'sms' },
    // { value: 14, name: 'WebApi' },
    {
      value: 15,
      name: 'GMB',
      key: 'EnableGoogleMyBusiness',
      selectorType: 'social'
    },
    {
      value: 16,
      name: 'OptIn',
      key: 'OptIn',
      selectorType: 'sms'
    }
  ],
  enum_Devices: [
    { value: 1, name: 'Desktop' },
    { value: 2, name: 'Mobile' },
    { value: 3, name: 'Tablet' },
    { value: 4, name: 'Android' },
    { value: 5, name: 'IOS' }
  ],
  enum_UserAgentOperator: [
    { value: 2, name: 'Agent' },
    { value: 3, name: 'Operator' }
  ],
  enum_Workflow: [
    { value: 1, name: 'Standard' },
    { value: 2, name: 'Simple Schedule' }
  ],
  enum_Schedule: [
    { value: 0, name: 'No schedule' },
    { value: 1, name: 'Daily' },
    { value: 2, name: 'Weekly' },
    { value: 3, name: 'Monthly' }
  ],
  enum_Country: [
    { value: 1, name: 'Other' },
    { value: 2, name: 'United States' },
    { value: 3, name: 'Canada' },
    { value: 4, name: 'Australia' }
  ],
  enum_Provision: [
    { value: 1, name: 'Adding Information' },
    { value: 2, name: 'Waiting For Setup' },
    { value: 3, name: 'Need More Information' },
    { value: 4, name: 'Pending Addition To Website' },
    { value: 5, name: 'Completed' }
  ],
  enum_ChatStatus: [
    /// The chat has been initiated by the visitor, but not picked up by the agent
    { value: 1, name: 'AwaitingPickup' },
    /// The chat has been picked up by an agent
    { value: 2, name: 'Active' },
    /// All users have left the chat
    { value: 3, name: 'Inactive' },
    /// A chat instance has triggered, but we don't want to show in the dash yet
    { value: 4, name: 'Requested' },
    /// The chat is currently being handled by the Virtual Assistant
    { value: 5, name: 'BotHandling' },
    /// The chat is currently being handled by the Script Bot
    { value: 6, name: 'ScriptBotHandling' },
    /// The chat is idled for certain amount of time (which is less then chat end time) is marked as shelved
    { value: 7, name: 'Shelved' },
    { value: 8, name: 'ClientHandling' }
  ],
  enum_TransferChatType: [
    { value: 1, name: 'OperatorToOperator' },
    { value: 2, name: 'TransferToLanguage' },
    { value: 3, name: 'AgentToOperatorWeb' },
    { value: 4, name: 'AgentToOperatorSMS' },
    { value: 5, name: 'ScriptBotTransferToLiveAgent' }
  ],
  enum_Reporting: [
    { value: 0, name: 'Queued' },
    { value: 1, name: 'Running' },
    { value: 2, name: 'Completed' },
    { value: 3, name: 'Completed Without Data' },
    { value: 4, name: 'Failed' }
  ],
  enum_Roles: [
    { value: 'SuperAdministrator', name: 'SuperAdministrator' },
    { value: 'Administrator', name: 'Administrator' },
    { value: 'LimitedAdministrator', name: 'LimitedAdministrator' },
    { value: 'PartnerAdministrator', name: 'PartnerAdministrator' },
    { value: 'PartnerSupervisor', name: 'PartnerSupervisor' },
    { value: 'Reporter', name: 'Reporter' },
    { value: 'CompanyViewer', name: 'CompanyViewer' },
    { value: 'CompanyEditor', name: 'CompanyEditor' },
    {
      value: 'CompanyContactInformationEditor',
      name: 'CompanyContactInformationEditor'
    },
    { value: 'ChatViewer', name: 'ChatViewer' },
    { value: 'ChatEditor', name: 'ChatEditor' },
    {
      value: 'ChatContactInformationViewer',
      name: 'ChatContactInformationViewer'
    },
    { value: 'LeadViewer', name: 'LeadViewer' },
    { value: 'LeadEditor', name: 'LeadEditor' },
    { value: 'ProvisioningRequestViewer', name: 'ProvisioningRequestViewer' },
    { value: 'ProvisioningRequestEditor', name: 'ProvisioningRequestEditor' },
    { value: 'ContactInformationEditor', name: 'ContactInformationEditor' },
    { value: 'Reviewer', name: 'Reviewer' },
    { value: 'BasicAccountsManager', name: 'BasicAccountsManager' },
    { value: 'ManagementDashboard', name: 'ManagementDashboard' },
    { value: 'Designer', name: 'Designer' },
    { value: 'IsrTrainer', name: 'IsrTrainer' },
    { value: 'OpsManager', name: 'OpsManager' },
    { value: 'BillingManager', name: 'BillingManager' },
    { value: 'ScriptViewer', name: 'ScriptViewer' },
    { value: 'ScriptEditor', name: 'ScriptEditor' },
    { value: 'ThemeEditor', name: 'ThemeEditor' }
  ],
  enum_SlideTypes: {
    companies: {
      value: 'hasCompanyTourViewed'
    },
    chat: {
      value: 'hasChatTourViewed'
    },
    customer: {
      value: 'hasCustomerTourViewed'
    },
    onboarding: {
      value: 'hasOnBoardingTourViewed'
    },
    report: {
      value: 'hasReportTourViewed'
    }
  },
  enum_QuickStats: {
    TotalVisits: { percentage: false },
    TotalChats: { percentage: false },
    TotalLeads: { percentage: false },
    TotalBillableLeads: { percentage: false },
    ClickThroughRate: { percentage: true },
    LeadConversionRate: { percentage: true },
    BlankChats: { percentage: false },
    ActiveCallConnect: { percentage: false },
    CallConnectAttempts: { percentage: false },
    CallConnectDuration: { percentage: false },
    ActiveChats: { percentage: false }
  },
  enum_ImageSource: [
    { src: '/undraw_Hello_re_3evm.png', alt: 'Welcome tour Image' },
    { src: '/finish.png', alt: 'Finish tour Image' }
  ],

  enum_GraphTypeEnum: [
    { name: 'Chats vs Leads', value: 10 },
    { name: 'Features Usage', value: 1 },
    { name: 'Chat Stats By', value: 2 },
    { name: 'Top/Bottom Lead Accounts', value: 5 },
    // non-filterable-graphs
    { name: 'Chat Referrals', value: 6 },
    { name: 'Accounts Activation', value: 3 },
    { name: 'Top Inactive Billers', value: 4 },
    { name: 'Budget/Pacing', value: 7 },
    { name: 'Agent Images CTR', value: 8 },
    { name: 'Accounts', value: 9 }
  ],
  enum_TimeIntervalsEnum: [
    { name: 'Last 24 Hours', value: TimeIntervalsEnum.Day },
    { name: 'Last 7 Days', value: TimeIntervalsEnum.Week },
    { name: 'Last 30 Days', value: TimeIntervalsEnum.Month },
    { name: 'Last 6 Months', value: TimeIntervalsEnum.SixMonths },
    { name: 'Last 12 Months', value: TimeIntervalsEnum.Year }
  ],
  enum_WeekDays: [
    { name: 'monday', value: 1 },
    { name: 'tuesday', value: 2 },
    { name: 'wednesday', value: 3 },
    { name: 'thursday', value: 4 },
    { name: 'friday', value: 5 },
    { name: 'saturday', value: 6 },
    { name: 'sunday', value: 0 }
  ],
  enum_reportType: [
    { value: 1, name: 'Traffic_Report_By_Date' },
    { value: 2, name: 'Traffic_Summary' },
    { value: 3, name: 'Leads' },
    { value: 4, name: 'Billable_Leads' },
    { value: 5, name: 'Transcripts' },
    { value: 6, name: 'Stats' },
    { value: 7, name: 'Calls_Summary' },
    { value: 8, name: 'Leads_Delay' },
    { value: 9, name: 'Agent_Response_Time' },
    { value: 10, name: 'Summary_Response_Time' },
    { value: 11, name: 'Review_Ratings' },
    { value: 12, name: 'High_Volume_Accounts' },
    { value: 13, name: 'Paused_Companies' },
    { value: 14, name: 'Often_Over_Budget_Companies' },
    { value: 15, name: 'Nearly_Over_Budget_Companies' },
    { value: 16, name: 'Invalid_Email_Addresses' },
    { value: 17, name: 'Performance' },
    { value: 18, name: 'Profile_Response' },
    { value: 19, name: 'Active_Companies_With_Low_Traffic' },
    { value: 20, name: 'Account_Companies_Without_Pricing' },
    { value: 21, name: 'Non_Account_Companies_Without_Pricing' },
    { value: 22, name: 'Active_Companies_Without_Invitation_Code' },
    { value: 23, name: 'Inactive_Companies_With_Invitation_Code' },
    { value: 24, name: 'Companies_With_Traffic_Pacing' },
    { value: 25, name: 'Companies_With_Billing_Amount' },
    { value: 26, name: 'Invitation_Code_Installed_Report' },
    { value: 27, name: 'Monthly_Minimum_Report' },
    { value: 28, name: 'Agent_Response_Time_By_Threshold' },
    // { value: 29, name: 'De_activated_accounts' },
    { value: 30, name: 'De_activated_accounts' },
    { value: 31, name: 'Facebook_Usage' }
  ],
  enum_CallStatus: [
    { value: -1, name: 'Idle' },
    { value: 1, name: 'Queued' },
    { value: 2, name: 'Ringing' },
    { value: 3, name: 'In Progress' },
    { value: 4, name: 'Completed' },
    { value: 5, name: 'Busy' },
    { value: 6, name: 'Failed' },
    { value: 7, name: 'No Answer' },
    { value: 8, name: 'Canceled' },
    { value: 9, name: 'Scheduled' },
    { value: 10, name: 'Initializing' }
  ],
  enum_chat_questionTypes: [
    { value: 0, name: 'None' },
    { value: 1, name: 'MultipleChoice' },
    { value: 2, name: 'TrueOrFalse' },
    { value: 3, name: 'Text' },
    { value: 4, name: 'Time' },
    { value: 5, name: 'TimeCalendar' },
    { value: 6, name: 'CalendarDate' },
    { value: 7, name: 'AllThatApply' },
    { value: 8, name: 'FullText' },
    { value: 9, name: 'AppointmentScheduler' },
    { value: 10, name: 'MultipleChoiceConsent' }
  ],
  enums_visitor_response_type: [
    { value: 1, name: 'Name' },
    { value: 2, name: 'Phone' },
    { value: 3, name: 'Email' },
    { value: 4, name: 'Message' },
    { value: 5, name: 'Region' },
    { value: 6, name: 'Address' }
  ],
  enum_chatEndedMsgs: [
    'The chat session has ended.',
    'This chatroom has been closed due to inactivity.'
  ],
  enum_chatLeftMsgs: [
    'The visitor has left the chat.',
    'All visitors have left the chat.'
  ],
  enum_messagingComponentType: [
    { value: 1, name: 'MDWrapper' },
    { value: 2, name: 'SignUpForm' },
    { value: 3, name: 'SpecialMessageComp' }
  ],
  enum_QT_visibility: [
    { value: 1, name: 'Agents' },
    { value: 2, name: 'Operators' },
    { value: 3, name: 'Both' },
    { value: 4, name: 'Self' }
  ],
  enum_tour_application: [
    { value: 1, name: 'Portal' },
    { value: 2, name: 'Messaging Dashboard' }
  ],

  enum_paymentTypes: [
    { value: 1, name: 'One Time' },
    { value: 2, name: 'Monthly' },
    { value: 3, name: 'Yearly' }
  ],

  enum_featureTypes: [
    { value: '1', name: 'SMS' },
    { value: '2', name: 'Exit Popup' },
    { value: '3', name: 'Document Signing' },
    { value: '4', name: 'Others' },
    { value: '5', name: 'Appointment Scheduling' },
    { value: '6', name: 'Spanish' },
    { value: '7', name: 'Facebook' }
  ],
  enum_trackerType: [
    { value: 1, name: 'GoogleAnalytics' },
    { value: 2, name: 'AzureAppInsight' }
  ],
  enum_appointmentStatus: [
    // { value: -1, name: 'Not Sent' },
    { value: 0, name: 'Not Sent', statusColor: 'warning' },
    { value: 1, name: 'Sent', statusColor: 'info' },
    { value: 2, name: 'Booked', statusColor: 'success' },
    { value: 3, name: 'Canceled', statusColor: 'danger' },
    { value: 4, name: 'Rescheduled', statusColor: 'purple' }
  ],
  enum_notificationState: [
    { value: 1, name: 'NotSent' },
    { value: 2, name: 'Sending' },
    { value: 3, name: 'Sent' },
    { value: 4, name: 'SendingFailed' }
  ],
  enum_docuSignNotificationState: [
    { value: 1, name: 'Document Not Sent', statusColor: 'warning' },
    { value: 2, name: 'Document Sent', statusColor: 'info' },
    { value: 3, name: 'Document Signed', statusColor: 'success' },
    { value: 4, name: 'Document Verified', statusColor: 'success' },
    { value: 5, name: 'Document Declined', statusColor: 'danger' }
  ],
  enum_ThemedWidgets: [
    { value: 1, name: 'RDChatWidget' },
    { value: 2, name: 'RDInvite' },
    { value: 3, name: 'ExitPopup' },
    { value: 4, name: 'RDPreChat' }
  ],
  enum_InvitationVersion: [
    { value: 0, name: 'Version_1' },
    { value: 1, name: 'Version_2' },
    { value: 2, name: 'Version_3' }
  ]
};

export const enum_hours = [
  { id: 0, name: '12:00 pm' },
  { id: 1, name: '1:00 am' },
  { id: 2, name: '2:00 am' },
  { id: 3, name: '3:00 am' },
  { id: 4, name: '4:00 am' },
  { id: 5, name: '5:00 am' },
  { id: 6, name: '6:00 am' },
  { id: 7, name: '7:00 am' },
  { id: 8, name: '8:00 am' },
  { id: 9, name: '9:00 am' },
  { id: 10, name: '10:00 am' },
  { id: 11, name: '11:00 am' },
  { id: 12, name: '12:00 am' },
  { id: 13, name: '1:00 pm' },
  { id: 14, name: '2:00 pm' },
  { id: 15, name: '3:00 pm' },
  { id: 16, name: '4:00 pm' },
  { id: 17, name: '5:00 pm' },
  { id: 18, name: '6:00 pm' },
  { id: 19, name: '7:00 pm' },
  { id: 20, name: '8:00 pm' },
  { id: 21, name: '9:00 pm' },
  { id: 22, name: '10:00 pm' },
  { id: 23, name: '11:00 pm' }
];
export const enum_Months = [
  { id: 1, name: 'Jan' },
  { id: 2, name: 'Feb' },
  { id: 3, name: 'Mar' },
  { id: 4, name: 'Apr' },
  { id: 5, name: 'May' },
  { id: 6, name: 'Jun' },
  { id: 7, name: 'Jul' },
  { id: 8, name: 'Aug' },
  { id: 9, name: 'Sep' },
  { id: 10, name: 'Oct' },
  { id: 11, name: 'Nov' },
  { id: 12, name: 'Dec' }
];
export const enum_Dates = [
  { id: 1, name: 'Day 1 of month' },
  { id: 2, name: 'Day 2 of month' },
  { id: 3, name: 'Day 3 of month' },
  { id: 4, name: 'Day 4 of month' },
  { id: 5, name: 'Day 5 of month' },
  { id: 6, name: 'Day 6 of month' },
  { id: 7, name: 'Day 7 of month' },
  { id: 8, name: 'Day 8 of month' },
  { id: 9, name: 'Day 9 of month' },
  { id: 10, name: 'Day 10 of month' },
  { id: 11, name: 'Day 11 of month' },
  { id: 12, name: 'Day 12 of month' },
  { id: 13, name: 'Day 13 of month' },
  { id: 14, name: 'Day 14 of month' },
  { id: 15, name: 'Day 15 of month' },
  { id: 16, name: 'Day 16 of month' },
  { id: 17, name: 'Day 17 of month' },
  { id: 18, name: 'Day 18 of month' },
  { id: 19, name: 'Day 19 of month' },
  { id: 20, name: 'Day 20 of month' },
  { id: 21, name: 'Day 21 of month' },
  { id: 22, name: 'Day 22 of month' },
  { id: 23, name: 'Day 23 of month' },
  { id: 24, name: 'Day 24 of month' },
  { id: 25, name: 'Day 25 of month' },
  { id: 26, name: 'Day 26 of month' },
  { id: 27, name: 'Day 27 of month' },
  { id: 28, name: 'Day 28 of month' },
  { id: 29, name: 'Day 29 of month' },
  { id: 30, name: 'Day 30 of month' },
  { id: 31, name: 'Day 31 of month' },
  { id: -1, name: 'Last Day of the month' }
];

export const APIStatus = {
  OK: 200,
  NoContent: 204,
  ExpectationFailed: 417,
  NotFound: 404
};
export const ReportStatus = {
  Queued: 0,
  Running: 1,
  Completed: 2,
  Completed_Without_Data: 3,
  Failed: 4
};
export const getObjectKeyByValue = (object, value, textCase) => {
  let formatedText = Object.keys(object).find(
    key => object[key] === parseInt(value)
  );
  if (textCase) return camelCaseStringToNormalText(formatedText, textCase);
  else return formatedText;
};
export const RoomType = {
  ActiveChat: 1,
  AllChat: 2,
  IncomingChat: 3,
  ShelvedChat: 4,
  unsentLead: 5
};

const ServerMessageType = {
  GroupAdded: 1,
  GroupRemoved: 2
};

const ChatMessageType = {
  Simulated: 2147483647,
  Visitor: 0,
  System: -1,
  Bot: -2,
  Prechat: -3,
  Predefined: -4,
  NonVisitorSystem: -6, // system message to be shown on only dashboard end, and not on visitor end
  PokeMessage: -7
};

const ChatPriority = {
  Normal: 1,
  Medium: 2,
  High: 3
};

export const Loading_enums = { Circular: 1, RippleGif: 2 };

const _enums = {
  AnalyticsTracking: dropdownEnums.createEnum(
    dropdownEnums.enum_AnalyticsTracking
  ),
  Relationship: dropdownEnums.createEnum(dropdownEnums.enum_Relationship),
  UserType: dropdownEnums.createEnum(dropdownEnums.enum_UserType),
  Roles: dropdownEnums.createEnum(dropdownEnums.enum_Roles),
  graphType: dropdownEnums.createEnum(dropdownEnums.enum_GraphTypeEnum),
  durations: dropdownEnums.createEnum(dropdownEnums.enum_TimeIntervalsEnum),
  WeekDays: dropdownEnums.createEnum(dropdownEnums.enum_WeekDays),
  ScheduleType: dropdownEnums.createEnum(dropdownEnums.enum_Schedule),
  ReportType: dropdownEnums.createEnum(dropdownEnums.enum_reportType),
  ChatStatus: dropdownEnums.createEnum(dropdownEnums.enum_ChatStatus),
  LeadType: dropdownEnums.createEnum(dropdownEnums.enum_LeadType),
  InitiatedBy: dropdownEnums.createEnum(dropdownEnums.enum_InitiatedBy),
  TransferChatType: dropdownEnums.createEnum(
    dropdownEnums.enum_TransferChatType
  ),
  CallConnectStatus: dropdownEnums.createEnum(dropdownEnums.enum_CallStatus),
  ChatQuestionType: dropdownEnums.createEnum(
    dropdownEnums.enum_chat_questionTypes
  ),
  VisitorResponseType: dropdownEnums.createEnum(
    dropdownEnums.enums_visitor_response_type
  ),
  RoomType: RoomType,
  ServerMessageType: ServerMessageType,
  ChatMessageType: ChatMessageType,
  ChatPriority: ChatPriority,
  ChatEndedMessages: dropdownEnums.enum_chatEndedMsgs,
  ChatLeftMsgs: dropdownEnums.enum_chatLeftMsgs,
  MessagingComponentType: dropdownEnums.createEnum(
    dropdownEnums.enum_messagingComponentType
  ),
  QTVisibility: dropdownEnums.createEnum(dropdownEnums.enum_QT_visibility),
  TourApplication: dropdownEnums.createEnum(
    dropdownEnums.enum_tour_application
  ),
  PaymentTypes: dropdownEnums.createEnum(dropdownEnums.enum_paymentTypes),
  FeatureTypes: dropdownEnums.createEnum(dropdownEnums.enum_featureTypes),
  TrackerType: dropdownEnums.createEnum(dropdownEnums.enum_trackerType),
  ThemedWidget: dropdownEnums.createEnum(dropdownEnums.enum_ThemedWidgets),
  InvitationVersion: dropdownEnums.createEnum(
    dropdownEnums.enum_InvitationVersion
  ),
  AppointmentStatus: dropdownEnums.createEnum(
    dropdownEnums.enum_appointmentStatus
  ),
  ProvisionStatus: dropdownEnums.createEnum(dropdownEnums.enum_Provision)
};

// Export Enums separately
export const enum_Relationship = dropdownEnums.enum_Relationship;
export const enum_LogLevel = dropdownEnums.enum_LogLevel;
export const enum_ChatFlowEnableFlag = dropdownEnums.enum_ChatFlowEnableFlag;
export const enum_InitiatedBy = dropdownEnums.enum_InitiatedBy;
export const Enums = _enums;
export default dropdownEnums;
